"use strict";
export class ComponentUtil {
  /**
   * 不断搜索父组件直至找到目标组件或者到根
   * @param target 
   * @param component 
   */
  static parents(target, component) {
    let _parent = component.$parent;
    while (true) {
      if (_parent instanceof target) {
        return _parent;
      }
      _parent = _parent.$parent;
      if (!_parent) {
        return null;
      }
    }
  }
}
