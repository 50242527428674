"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
let RobotUploadQa = class extends Vue {
  constructor() {
    super(...arguments);
    this.active = 0;
    this.reqstatus = true;
    // 是否显示弹窗
    this.dialogVisible = false;
    this.filesList = new Array();
    this.isupload = false;
  }
  //下载导入模板
  download() {
    return __async(this, null, function* () {
      let resfile = yield API.get(
        `api://base.auth/v1/chatbots/train/qa/import-template`,
        {
          responseType: "blob"
        }
      );
      var debug = resfile;
      if (debug) {
        var elink = document.createElement("a");
        elink.download = "Importedtable.xlsx";
        elink.style.display = "none";
        var blob = debug.data;
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      } else {
        this.$message.error("Import failure");
      }
    });
  }
  rmfile(file, fileList) {
    this.filesList = fileList;
    if (fileList.length == 0) {
      this.isupload = false;
    }
  }
  changefile(file, fileList) {
    const isLt5M = file.size / 1024 / 1024 < 5;
    this.filesList.push(file);
    if (fileList.length > 0) {
      this.isupload = true;
    }
  }
  uploadRecord(file) {
    return __async(this, null, function* () {
      this.files = file;
      const isLt5M = file.size / 1024 / 1024 < 5;
      let fileFormData = new FormData();
      fileFormData.append("train_file", file);
      yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/qa-file`,
        fileFormData
      );
      return false;
    });
  }
  submitUpload() {
    this.active++;
    this._upload.submit();
    this.reqstatus = false;
    this.active++;
  }
  // trainingQa() {
  //   this.close();
  //   this.$emit("trainingQa");
  // }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
    this.$emit("reloadPart");
  }
};
__decorateClass([
  Ref("upload")
], RobotUploadQa.prototype, "_upload", 2);
RobotUploadQa = __decorateClass([
  Component({
    components: {}
  })
], RobotUploadQa);
export {
  RobotUploadQa as default
};
