"use strict";
export default class Scanner {
  //:Generator<__WebpackModuleApi.Module>
  static scanner(context) {
    const _modules = [];
    for (const _moduleName of context.keys()) {
      if (["./", "./index", "./index.ts"].indexOf(_moduleName) !== -1) {
        continue;
      }
      const _contexts = context(_moduleName);
      for (const _module in _contexts) {
        _modules.push(_contexts[_module]);
      }
    }
    return _modules;
  }
}
