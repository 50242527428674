"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import builder from "@rsql/builder";
import { emit } from "@rsql/emitter";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import { dashboardapi } from "../../services/dashboard.api";
import { robotsettingsApi } from "../../../setting/services/robotsettings.api";
import ShowSources from "../dialog/ShowSources.vue";
import ReAnswer from "../dialog/ReAnswer.vue";
import datetime from "@/utils/time-format/index";
let Conversations = class extends Vue {
  constructor() {
    super(...arguments);
    this.datesearch = [
      new Date().setDate(new Date().getDate() - 30),
      new Date().setHours(23, 59, 59, 999)
    ];
    this.chatroomList = [];
    this.chatRecord = [];
    this.page = {
      model: "page",
      current: 1,
      total: 0,
      size: 20,
      total_page: 1
    };
    this.visitorId = "";
    this.shareid = "";
    this.between = "=between=";
    this.accountInfo = AccountInfoService.getAccountInfo();
    this.timeago = datetime.format_login_time;
    this.isAllHistory = false;
    //最后一条消息记录id
    this.message_id = "";
    this.sortrsql = emit(
      builder.and(builder.comparison("updatedTime", "=sort=", "desc"))
    );
    this.chatboxscrollTop = 0;
    this.showsourcesData = {};
    this.reAnswer = {};
  }
  welcomeMessageChanged(val, oldVal) {
    return __async(this, null, function* () {
      let start = String(Math.floor(val[0] / 1e3));
      let end = String(Math.floor(val[1] / 1e3));
      let rsql = emit(builder.and(builder.le("createdTime", end))) + ";" + emit(builder.and(builder.ge("createdTime", start))) + ";" + this.sortrsql;
      let resData = yield dashboardapi.getChatroom(
        this.$route.query.id,
        {
          search: rsql,
          size: 20,
          page: 1
        }
      );
      this.chatroomList = resData.result.datas;
      this.page = resData.page;
      if (this.chatroomList.length != 0) {
        this.dataCollectionRecord = this.chatroomList[0].dataCollectionRecord;
        this.visitorId = this.chatroomList[0].id;
        this.chatroom = this.chatroomList[0];
      }
      let res = yield dashboardapi.getChatroomHistory(this.visitorId);
      if (res.datas.length !== 0) {
        this.message_id = res.datas[res.datas.length - 1].id;
      }
      res.datas.forEach((i, index) => {
        if (i.role == "USER") {
          this.chatRecord.unshift({
            content: i.content,
            user: "my",
            time: i.createdTime
          });
        } else {
          this.chatRecord.unshift({
            content: i.content,
            user: "robot",
            message_id: i.id,
            askQuestion: res.datas[index + 1].content
          });
        }
      });
      this.updatemsg();
    });
  }
  mounted() {
    return __async(this, null, function* () {
      this.shareid = (yield robotsettingsApi.getGeneral(this.$route.query.id)).shareId;
    });
  }
  currentChange(current) {
    return __async(this, null, function* () {
      let start = String(Math.floor(this.datesearch[0] / 1e3));
      let end = String(Math.floor(this.datesearch[1] / 1e3));
      let rsql = emit(builder.and(builder.le("createdTime", end))) + ";" + emit(builder.and(builder.ge("createdTime", start))) + ";" + this.sortrsql;
      let resData = yield dashboardapi.getChatroom(
        this.$route.query.id,
        {
          search: rsql,
          size: 20,
          page: current
        }
      );
      this.chatroomList = resData.result.datas;
      this.page = resData.page;
    });
  }
  updatemsg() {
    this.$nextTick(() => {
      let div = document.getElementById("chat-box");
      div.scrollTop = div.scrollHeight;
    });
  }
  toggleroom(item, roomindex) {
    return __async(this, null, function* () {
      this.chatroom = item;
      this.chatroomList[roomindex].hasUnreadMessages = false;
      this.isAllHistory = false;
      this.chatRecord = [];
      this.dataCollectionRecord = item.dataCollectionRecord;
      this.visitorId = item.id;
      let res = yield dashboardapi.getChatroomHistory(this.visitorId);
      if (res.datas.length !== 0) {
        this.message_id = res.datas[res.datas.length - 1].id;
      }
      let index = 1;
      res.datas.forEach((i) => {
        if (i.role == "USER") {
          this.chatRecord.unshift({
            content: i.content,
            user: "my",
            time: i.createdTime
          });
        } else {
          this.chatRecord.unshift({
            content: i.content,
            user: "robot",
            message_id: i.id,
            askQuestion: res.datas[index].content
          });
        }
        index++;
      });
      this.updatemsg();
    });
  }
  divscrolling() {
    return __async(this, null, function* () {
      if (this.isAllHistory) {
        return;
      }
      let divchat = document.getElementById("chat-box");
      if (divchat.scrollTop == 1) {
        if (this.message_id) {
          let res = yield dashboardapi.getChatroomHistory(
            this.visitorId,
            this.message_id
          );
          if (res.datas.length !== 0) {
            this.message_id = res.datas[res.datas.length - 1].id;
            res.datas.forEach((i, index) => {
              if (i.role == "USER") {
                this.chatRecord.unshift({
                  content: i.content,
                  user: "my",
                  time: i.createdTime
                });
              } else {
                this.chatRecord.unshift({
                  content: i.content,
                  user: "robot",
                  message_id: i.id,
                  askQuestion: res.datas[index + 1].content
                });
              }
            });
            this.$nextTick(() => {
              let x = document.querySelectorAll(
                `#chat-box>.chat-data:nth-child(${res.datas.length + 1})`
              );
              this.chatboxscrollTop = x[0].offsetTop - x[0].offsetHeight + 40;
              divchat.scrollTop = this.chatboxscrollTop;
            });
          } else {
            this.isAllHistory = true;
          }
        }
      }
    });
  }
  openShowSources(data) {
    return __async(this, null, function* () {
      this.showsourcesData = {
        question: data.askQuestion,
        answer: data.content,
        message_id: data.message_id
      };
      this._ShowSources.open();
    });
  }
  openReanswer(data) {
    return __async(this, null, function* () {
      this.reAnswer = {
        question: data.askQuestion,
        answer: data.content
      };
      this._ReAnswer.open();
    });
  }
  showtime(item, index) {
    if (index == 0) {
      return this.$dayjs((item == null ? void 0 : item.time) * 1e3).format("DD MMM HH:mm");
    }
    if (new Date(item.time * 1e3).toDateString() !== new Date(this.chatRecord[index - 2].time * 1e3).toDateString()) {
      return this.$dayjs((item == null ? void 0 : item.time) * 1e3).format("DD MMM HH:mm");
    }
    if (item.time - this.chatRecord[index - 2].time < 90) {
      return false;
    }
    return this.$dayjs((item == null ? void 0 : item.time) * 1e3).format("HH:mm");
  }
};
__decorateClass([
  Ref("ShowSources")
], Conversations.prototype, "_ShowSources", 2);
__decorateClass([
  Ref("ReAnswer")
], Conversations.prototype, "_ReAnswer", 2);
__decorateClass([
  Watch("datesearch", { immediate: true, deep: true })
], Conversations.prototype, "welcomeMessageChanged", 1);
Conversations = __decorateClass([
  Component({
    components: { ShowSources, ReAnswer }
  })
], Conversations);
export {
  Conversations as default
};
