"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Prop } from "vue-property-decorator";
let RobotProgress = class extends Vue {
  constructor() {
    super(...arguments);
    this.dialogVisible = false;
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    setTimeout(() => {
      this.dialogVisible = false;
    }, 1e3);
    setTimeout(() => {
      this.$store.commit("progress/update", 0);
    }, 1500);
  }
};
__decorateClass([
  Prop(Object)
], RobotProgress.prototype, "dialogobj", 2);
RobotProgress = __decorateClass([
  Component({
    components: {}
  })
], RobotProgress);
export {
  RobotProgress as default
};
