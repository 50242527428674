"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { Message } from "element-ui";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import UiSubmit from "@/components/form/UiSubmit.vue";
import API from "@ym-framework/api-utils";
import { robotsettingsApi } from "../../services/robotsettings.api";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.id = "";
    this.orgId = "";
    this.accountId = "";
    this.cosplayId = "";
    this.shareId = "";
    this.name = "";
    this.basePrompt = "";
    this.unansweredMessage = "";
    this.manufacturesProvider = {
      manufacturers: "",
      model: ""
    };
    this.visibility = "";
    this.domains = [];
    this.enableThrottlingMode = "false";
    this.enableContextual = "false";
    this.rateLimit = {
      model: "",
      messageSize: 0,
      rangeSeconds: 0,
      failurePrompt: "",
      used: false
    };
  }
}
let General = class extends Vue {
  constructor() {
    super(...arguments);
    // 创建机器人设置表单controller
    this.controller = new FormController();
    this.accountInfo = AccountInfoService.getAccountInfo();
    // lastTrain: number = 0;
    this.chatbottype = "";
  }
  mounted() {
    return __async(this, null, function* () {
      let obj = yield robotsettingsApi.getGeneral(this.$route.query.id);
      Object.keys(obj).forEach((key) => {
        this.controller[key] = obj[key];
      });
      this.setChatbotType();
    });
  }
  onLocaleChanged(newLocale, oldLocale) {
    return __async(this, null, function* () {
      this.setChatbotType();
    });
  }
  setChatbotType() {
    return __async(this, null, function* () {
      if (this.controller.cosplayId == "10001") {
        this.chatbottype = this.$t("home.knowledge");
      } else {
        this.chatbottype = this.$t("home.sales");
      }
    });
  }
  copyChatbotid() {
    navigator.clipboard.writeText(`${this.controller.shareId}`).then(() => {
      this.$message.success(this.$t("route.success"));
    });
  }
  rebaseprompt() {
    return __async(this, null, function* () {
      this.controller.basePrompt = (yield robotsettingsApi.reset(this.$route.query.id, {
        reset_type: "BASE_PROMPT"
      })).message;
    });
  }
  reunansweredMessage() {
    return __async(this, null, function* () {
      this.controller.unansweredMessage = (yield robotsettingsApi.reset(this.$route.query.id, {
        reset_type: "UNANSWERED_MESSAGE"
      })).message;
    });
  }
  relimit() {
    this.controller.rateLimit.messageSize = 30;
    this.controller.rateLimit.rangeSeconds = 240;
  }
  submit() {
    return __async(this, null, function* () {
      try {
        this.controller.status = UiFormStatus.LOADING;
        yield API.put(
          `api://base.auth/v1/chatbots/${this.$route.query.id}/setting/general`,
          this.controller
        ).then(() => {
          Message.success(this.$t("settings.generl.success"));
        });
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
};
__decorateClass([
  Ref("Form")
], General.prototype, "_form", 2);
__decorateClass([
  Watch("$root.$i18n.locale", { deep: true, immediate: true })
], General.prototype, "onLocaleChanged", 1);
General = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], General);
export {
  General as default
};
