"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiForm, {
  UiFormController
} from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import { robotsettingInfoAPI } from "@/shared/api/robot-info/robot-setting-info.api";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { robotsettingsApi } from "../../../setting/services/robotsettings.api";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.name = "My Chatbot 1";
    this.cosplayId = "10003";
  }
}
let RobotCreateDialog = class extends Vue {
  constructor() {
    super(...arguments);
    // 创建机器人表单controller
    this.controller = new FormController();
    // 是否显示弹窗
    this.dialogVisible = false;
    this.cosplaylist = [];
    this.robotlist = [];
    this.chatbottype = "";
  }
  mounted() {
    return __async(this, null, function* () {
      this.cosplaylist = (yield robotsettingInfoAPI.getChatbotCosplay()).datas;
    });
  }
  submit() {
    return __async(this, null, function* () {
      this.close();
      yield API.post(`api://base.auth/v1/chatbots`, this.controller).catch(
        (err) => {
          throwErrorApi.throwError(err);
        }
      );
      this.$emit("addQaLoading");
      console.log(this.controller.name);
      this.robotlist = (yield API.get(`api://base.auth/v1/chatbots`, {
        params: { size: 50, page: 1 }
      })).data.result.datas;
      this.robotlist.forEach((i) => __async(this, null, function* () {
        if (i.name == this.controller.name) {
          this.$store.commit("chatbot/getnowbotname", i.name);
          this.$store.commit(
            "chatbot/getshareId",
            (yield robotsettingsApi.getGeneral(i.id)).shareId
          );
          this.$router.push({
            path: "/train",
            query: {
              id: i.id
            }
          });
          return;
        }
      }));
    });
  }
  // 打开弹窗
  open(num) {
    return __async(this, null, function* () {
      this.dialogVisible = true;
      this.controller.name = `My Chatbot ${num}`;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
};
__decorateClass([
  Ref("Form")
], RobotCreateDialog.prototype, "_form", 2);
RobotCreateDialog = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], RobotCreateDialog);
export {
  RobotCreateDialog as default
};
