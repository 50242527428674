"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import { robottrainAPI } from "../../services/robottrain.api";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import builder from "@rsql/builder";
import { emit } from "@rsql/emitter";
let RobotFiles = class extends Vue {
  constructor() {
    super(...arguments);
    this.rsql = emit(builder.and(builder.comparison("createdTime", "=sort=", "desc")));
    //文件列表获取数据的controller实例
    this.gridController = new GridController(
      new APIDataSource(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/file`
      ),
      { search: this.rsql }
    );
    this.uploading = "";
  }
  mounted() {
    return __async(this, null, function* () {
      this.uploading = (yield robottrainAPI.upload()).maxFileSize;
    });
  }
  uploadRecord(file) {
    return __async(this, null, function* () {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let fileFormData = new FormData();
      fileFormData.append("train_file", file);
      yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/qa-file`,
        fileFormData
      ).then(() => __async(this, null, function* () {
        yield this.gridController.search();
        robottrainAPI.getUsed(this.$route.query.id);
      }));
    });
  }
  delFile(id) {
    return __async(this, null, function* () {
      yield robottrainAPI.delFile(this.$route.query.id, id);
      yield this.gridController.search();
      robottrainAPI.getUsed(this.$route.query.id);
    });
  }
};
RobotFiles = __decorateClass([
  Component({
    components: {
      UiGrid
    }
  })
], RobotFiles);
export {
  RobotFiles as default
};
