"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Leads from "./components/Leads.vue";
import Conversations from "./components/Conversations.vue";
let Dashboard = class extends Vue {
};
Dashboard = __decorateClass([
  Component({
    components: {
      Layout,
      Leads,
      Conversations
    }
  })
], Dashboard);
export {
  Dashboard as default
};
