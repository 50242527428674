"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Prop } from "vue-property-decorator";
import UiForm from "./UiForm.vue";
import { ComponentUtil } from "@/framework/components/utils";
let UiSubmit = class extends Vue {
  submit() {
    return __async(this, null, function* () {
      const _component = ComponentUtil.parents(UiForm, this);
      if (_component != null) {
        _component.submit();
      }
    });
  }
};
__decorateClass([
  Prop(String)
], UiSubmit.prototype, "type", 2);
UiSubmit = __decorateClass([
  Component
], UiSubmit);
export {
  UiSubmit as default
};
