"use strict";
import "reflect-metadata";
export var UiFormAnnotations;
((UiFormAnnotations2) => {
  function Rule(rules) {
    return function(target, name) {
      Reflect.defineMetadata("form.rules", rules, target, name);
    };
  }
  UiFormAnnotations2.Rule = Rule;
  function DefaultValue(value) {
    return function(target, name) {
      Reflect.defineMetadata("form.default.value", value, target, name);
    };
  }
  UiFormAnnotations2.DefaultValue = DefaultValue;
})(UiFormAnnotations || (UiFormAnnotations = {}));
