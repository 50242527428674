"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Prop } from "vue-property-decorator";
import UiForm, {
  UiFormController
} from "@/components/form/UiForm.vue";
import API from "@ym-framework/api-utils";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.formarray = [
      {
        question: "",
        answer: ""
      }
    ];
  }
}
let Answer = class extends Vue {
  constructor() {
    super(...arguments);
    // 创建表单controller
    this.controller = new FormController();
    this.dialogVisible = false;
    this.isloading = false;
  }
  submit() {
    return __async(this, null, function* () {
      yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/qa`,
        this.controller.formarray
      );
      this.close();
      this.$emit("trainingQa");
    });
  }
  //删除一个
  remove(item) {
    let index = this.controller.formarray.indexOf(item);
    if (index !== -1) {
      this.controller.formarray.splice(index, 1);
    }
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.dialogVisible = true;
      this.isloading = true;
      let list = (yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/generate-unanswered-questions`
      )).data.unansweredQuestions;
      this.isloading = false;
      this.controller.formarray = [];
      list.forEach((item) => {
        this.controller.formarray.push({
          question: item,
          answer: ""
        });
      });
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
};
__decorateClass([
  Prop()
], Answer.prototype, "unanswered", 2);
Answer = __decorateClass([
  Component({
    components: { UiForm }
  })
], Answer);
export {
  Answer as default
};
