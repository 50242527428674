"use strict";
const menu = [
  {
    title: "layout.chatbot",
    to: "/chatbot",
    index: "2",
    icon: "el-icon-chat-line-round"
  },
  {
    title: "layout.train",
    to: "/train",
    index: "3",
    icon: "el-icon-edit-outline"
  },
  {
    title: "layout.settings",
    to: "/settings",
    index: "4",
    icon: "el-icon-setting"
  },
  {
    title: "layout.dashboard",
    to: "/dashboard",
    index: "5",
    icon: "el-icon-date"
  },
  {
    title: "layout.analytics",
    to: "/analytics",
    index: "6",
    icon: "el-icon-odometer"
  },
  {
    title: "layout.intergrations",
    to: "/intergrations",
    index: "7",
    icon: "el-icon-set-up"
  },
  {
    title: "layout.share",
    to: "/share",
    index: "8",
    icon: "el-icon-share"
  }
];
export default menu;
