import { render, staticRenderFns } from "./RobotUploadQa.vue?vue&type=template&id=630bd755&scoped=true&"
import script from "./RobotUploadQa.vue?vue&type=script&lang=ts&"
export * from "./RobotUploadQa.vue?vue&type=script&lang=ts&"
import style0 from "./RobotUploadQa.vue?vue&type=style&index=0&id=630bd755&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630bd755",
  null
  
)

export default component.exports