"use strict";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
const userpackage = {
  namespaced: true,
  state: {
    nowpackage: {
      code: "",
      used: 0,
      initialTotal: 0,
      total: 0,
      quotaType: "",
      enhances: []
    },
    upgradeable: [],
    nowcombos: [
      {
        id: "",
        createdTime: 0,
        updatedTime: 0,
        type: "",
        productCode: "",
        startTime: 0,
        endTime: 0
      }
    ]
  },
  mutations: {
    update(state, newinfo) {
      state.nowpackage = newinfo;
    },
    updateupgradeable(state, newinfo) {
      state.upgradeable = newinfo;
    },
    updatenowcombos(state, newinfo) {
      state.nowcombos = newinfo;
    }
  }
};
const chatbot = {
  namespaced: true,
  state: {
    nowbotname: "",
    usagestatistics: {
      corpusStatistics: [
        {
          type: "QA",
          size: 0,
          usedCharacters: 0
        },
        {
          type: "FILE",
          size: 0,
          usedCharacters: 0
        },
        {
          type: "TEXT",
          size: 0,
          usedCharacters: 0
        },
        {
          type: "LINK",
          size: 0,
          usedCharacters: 0
        }
      ],
      totalUsedCharacters: 0,
      totalCharacters: 0
    },
    shareId: ""
  },
  mutations: {
    update(state, newinfo) {
      state.usagestatistics = newinfo;
    },
    getshareId(state, shareId) {
      state.shareId = shareId;
    },
    getnowbotname(state, name) {
      state.nowbotname = name;
    }
  }
};
const progress = {
  namespaced: true,
  state: {
    num: 0
  },
  mutations: {
    update(state, newdata) {
      state.num = newdata;
    }
  }
};
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    userpackage,
    chatbot,
    progress
  },
  plugins: [
    createPersistedState({
      key: "user",
      // 存数据的key名   自定义的  要有语义化
      paths: ["userpackage", "chatbot"]
      // 要把那些模块加入缓存
    })
  ]
});
