"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { robottrainAPI } from "../../services/robottrain.api";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import RobotProgress from "../dialog/RobotProgress.vue";
import WebsiteDialog from "../dialog/WebsiteDialog.vue";
let RobotWebsite = class extends Vue {
  constructor() {
    super(...arguments);
    // 网站列表获取数据的controller实例
    this.gridController = new GridController(
      new APIDataSource(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/website`
      ),
      { search: "createdTime=sort=desc" }
    );
    this.isloading = false;
    this.dialogobj = {
      title: "",
      formname: "",
      tip: "",
      btnname: "",
      linkType: "",
      placeholder: ""
    };
    this.traindialogobj = {
      title: this.$t("train.titile"),
      tip: this.$t("train.tip2")
    };
  }
  nowlongChanged(val, oldVal) {
    return __async(this, null, function* () {
      this.traindialogobj = {
        title: this.$t("train.titile"),
        tip: this.$t("train.tip2")
      };
    });
  }
  openprogress() {
    this._robotprogress.open();
  }
  getWeblist() {
    return __async(this, null, function* () {
      this._robotprogress.close();
      this.gridController.search();
      robottrainAPI.getUsed(this.$route.query.id);
    });
  }
  delWeblist(id) {
    return __async(this, null, function* () {
      yield robottrainAPI.delWebsite(this.$route.query.id, id);
      this.gridController.search();
      robottrainAPI.getUsed(this.$route.query.id);
    });
  }
  addurl(type) {
    if (type == "URL") {
      this.dialogobj = {
        title: this.$root.$i18n.t("train.add_url"),
        formname: "URL",
        tip: this.$root.$i18n.t("train.new_line"),
        btnname: "OK",
        linkType: "URL",
        placeholder: "https://www.example.com"
      };
    } else if (type == "WEBSITE_URL") {
      this.dialogobj = {
        title: this.$root.$i18n.t("train.crawl_website"),
        formname: this.$root.$i18n.t("train.website_url"),
        tip: this.$root.$i18n.t("train.craws_all"),
        btnname: this.$root.$i18n.t("train.fetch"),
        linkType: "WEBSITE_URL",
        placeholder: "https://www.example.com"
      };
    } else {
      this.dialogobj = {
        title: this.$root.$i18n.t("train.submit"),
        formname: this.$root.$i18n.t("train.submit"),
        tip: this.$root.$i18n.t("train.craws_links"),
        btnname: this.$root.$i18n.t("train.laod"),
        linkType: "SITEMAP",
        placeholder: "https://www.example.com/sitemap.xml"
      };
    }
    this._websitedialong.open();
  }
};
__decorateClass([
  Ref("robotprogress")
], RobotWebsite.prototype, "_robotprogress", 2);
__decorateClass([
  Ref("websitedialong")
], RobotWebsite.prototype, "_websitedialong", 2);
__decorateClass([
  Watch("$root.$i18n.locale", { deep: true })
], RobotWebsite.prototype, "nowlongChanged", 1);
RobotWebsite = __decorateClass([
  Component({
    components: {
      WebsiteDialog,
      UiGrid,
      RobotProgress
    }
  })
], RobotWebsite);
export {
  RobotWebsite as default
};
