"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue, Provide } from "vue-property-decorator";
let App = class extends Vue {
  constructor() {
    super(...arguments);
    this.isShow = true;
    this.reload = () => {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    };
  }
};
__decorateClass([
  Provide()
], App.prototype, "reload", 2);
App = __decorateClass([
  Component({})
], App);
export {
  App as default
};
