"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import { robotshareApi } from "../../../share/services/robotshare.api";
import UiForm, {
  UiFormController
} from "@/components/form/UiForm.vue";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import API from "@ym-framework/api-utils";
import Fingerprint2 from "fingerprintjs2";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.visitorId = "";
    this.name = "";
    this.email = "";
    this.phone = "";
    this.telegram = "";
  }
}
export class DataFormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.enableDataCollection = false;
    this.title = "Let me know how to contact you";
    this.enableVisitorNameCollection = false;
    this.visitorNameInputKey = "Name";
    this.visitorNamePrompt = "Enter your Name";
    this.visitorNameInputRequired = false;
    this.enableVisitorEmailCollection = false;
    this.visitorEmailInputKey = "Email";
    this.visitorEmailPrompt = "Enter your Email";
    this.visitorEmailInputRequired = false;
    this.enableVisitorPhoneCollection = false;
    this.visitorPhoneInputKey = "Phone";
    this.visitorPhonePrompt = "Enter your Phone";
    this.visitorPhoneInputRequired = false;
    this.enableVisitorTelegramCollection = false;
    this.visitorTelegramInputKey = "Telegram";
    this.visitorTelegramPrompt = "Enter your Telegram";
    this.visitorTelegramInputRequired = false;
    this.enableVisitorWhatsAppCollection = false;
    this.visitorWhatsAppInputKey = "WhatsApp";
    this.visitorWhatsAppPrompt = "Enter your WhatsApp";
    this.visitorWhatsAppInputRequired = false;
  }
}
let RobotChat = class extends Vue {
  constructor() {
    super(...arguments);
    //profilePicture
    this.profilePicture = { enableProfilePicture: false, profilePicture: "" };
    this.robotname = "";
    //用户输入的信息
    this.msg = "";
    //聊天记录
    this.chatRecord = [];
    //指纹
    this.visitorId = "";
    //建议列表
    this.suggestedlist = [];
    //是否展示建议列表
    this.isSuggest = false;
    //是否展示线索收集
    this.isShowDataForm = false;
    //判断当前用户是否已经记录数据收集
    this.isHaveDataForm = false;
    //第一条消息
    this.welcomeMessage = [];
    //是否展示初始消息
    this.isShowwel = false;
    //自动提示词
    this.automaticPrompt = false;
    //是否可以发生消息请求
    this.isPost = true;
    //网站入口
    this.Websiteportal = "";
    this.dataformcontroller = new DataFormController();
    this.controller = new FormController();
    //最后一条消息记录id
    this.message_id = "";
    this.robotid = window.location.href.slice(window.location.href.indexOf("id=") + 3);
    this.isloading = false;
    this.chatboxscrollTop = 0;
    //移除水印
    this.removableWatermark = false;
    //name校验规则
    this.nameRules = [];
    //邮箱校验规则
    this.emailRules = [];
    //phone校验规则
    this.phoneRules = [];
    //telegram校验规则
    this.telegramRules = [];
    //主题
    this.themeStyle = {
      "--fontSize": "14",
      "--suggestFontSize": "12",
      "--bubbleButtonColor": "rgb(0, 0, 0,0)",
      "--bgcolor": "rgb(0, 0, 0,0)",
      "--titlecolor": "rgb(0, 0, 0,0)",
      "--robotboxcolor": "rgb(0, 0, 0,0)",
      "--robotdatacolor": "rgb(0, 0, 0,0)",
      "--userboxcolor": "rgb(0, 0, 0,0)",
      "--userdatacolor": "rgb(0, 0, 0,0)",
      "--suggestboxcolor": "rgb(0, 0, 0,0)",
      "--suggestdatacolor": "rgb(0, 0, 0,0)",
      "--inputbgcolor": "rgb(0, 0, 0,0)",
      "--inputbordercolor": "rgb(0, 0, 0,0)",
      "--inputcolor": "rgb(0, 0, 0,0)",
      "--sendbtncolor": "rgb(0, 0, 0,0)"
    };
  }
  closeDataForm() {
    this.isShowDataForm = false;
  }
  submit() {
    this._dataForm.submit();
  }
  reloadPart() {
    return __async(this, null, function* () {
      this.isPost = true;
      let res = yield API.get(`api://base/public/v1/chatbots/${this.robotid}/visitor/${this.visitorId}/chat
-refresh`);
      this.isShowDataForm = false;
      this.isShowwel = true;
      this.chatRecord = [];
      this.isSuggest = true;
    });
  }
  mounted() {
    return __async(this, null, function* () {
      try {
        this.Websiteportal = window.parent.location.href;
      } catch (err) {
        this.Websiteportal = "";
      }
      let chatbotObj;
      if (this.$route.path == "/chatbot") {
        chatbotObj = yield robotshareApi.getShareSettings(
          this.$store.state.chatbot.shareId
        );
        this.robotid = this.$store.state.chatbot.shareId;
      } else {
        chatbotObj = yield robotshareApi.getShareSettings(
          this.$route.query.id
        );
      }
      this.automaticPrompt = chatbotObj.interfaceSetting.enableAutomaticPrompt;
      this.welcomeMessage = chatbotObj.interfaceSetting.welcomeMessage;
      let dataCollectionobj = chatbotObj.dataCollectionSetting;
      this.profilePicture = chatbotObj.interfaceSetting.profilePicture;
      this.robotname = chatbotObj.interfaceSetting.displayName;
      Object.keys(dataCollectionobj).forEach((key) => {
        if (dataCollectionobj[key].length !== 0) {
          this.dataformcontroller[key] = dataCollectionobj[key];
        }
      });
      this.removableWatermark = chatbotObj.interfaceSetting.removableWatermark;
      this.suggestedlist = chatbotObj.interfaceSetting.suggestedPrompts;
      let theme = chatbotObj.interfaceSetting.theme;
      this.$nextTick(() => {
        this.themeStyle = {
          "--fontSize": chatbotObj.interfaceSetting.themeSetting.fontSize,
          "--suggestFontSize": chatbotObj.interfaceSetting.themeSetting.suggestMessageFontSize,
          "--bubbleButtonColor": chatbotObj.interfaceSetting.bubbleButton.bubbleButtonColor,
          "--bgcolor": chatbotObj.interfaceSetting.themeSetting.backgroundColor,
          "--titlecolor": chatbotObj.interfaceSetting.theme === "LIGHT" ? "#000000" : "#FFFFFF",
          "--robotboxcolor": chatbotObj.interfaceSetting.themeSetting.botMessageColor,
          "--robotdatacolor": chatbotObj.interfaceSetting.themeSetting.botMessageFontColor,
          "--userboxcolor": chatbotObj.interfaceSetting.themeSetting.userMessageColor,
          "--userdatacolor": chatbotObj.interfaceSetting.themeSetting.userMessageFontColor,
          "--suggestboxcolor": chatbotObj.interfaceSetting.themeSetting.botMessageColor,
          "--suggestdatacolor": chatbotObj.interfaceSetting.themeSetting.botMessageFontColor,
          "--inputbgcolor": chatbotObj.interfaceSetting.theme === "LIGHT" ? "#FFFFFF" : "#000000",
          "--inputbordercolor": chatbotObj.interfaceSetting.theme === "LIGHT" ? "#DADDE5" : "#FFFFFF",
          "--inputcolor": chatbotObj.interfaceSetting.theme === "LIGHT" ? "#000000" : "#FFFFFF",
          "--sendbtncolor": chatbotObj.interfaceSetting.theme === "LIGHT" ? "#000000" : "#186AFF"
        };
      });
      if (this.$route.path == "/chatbot") {
        this.visitorId = this.$route.query.id;
        let res = yield robotshareApi.getShareChattingRecords(
          this.robotid,
          this.visitorId
        );
        if (res.datas.length !== 0) {
          this.message_id = res.datas[res.datas.length - 1].id;
        }
        res.datas.forEach((i) => {
          if (i.role == "USER") {
            this.chatRecord.unshift({
              content: i.content,
              user: "my"
            });
          } else {
            this.chatRecord.unshift({
              content: i.content,
              user: "robot"
            });
          }
        });
        if (res.datas.length < 10) {
          this.isShowwel = true;
        }
        this.updatemsg();
      } else if (localStorage.getItem("visitorId") != null) {
        this.visitorId = localStorage.getItem("visitorId");
        let res = yield robotshareApi.getShareChattingRecords(
          this.robotid,
          this.visitorId
        );
        if (res.datas.length !== 0) {
          this.message_id = res.datas[res.datas.length - 1].id;
        }
        res.datas.forEach((i) => {
          if (i.role == "USER") {
            this.chatRecord.unshift({
              content: i.content,
              user: "my"
            });
          } else {
            this.chatRecord.unshift({
              content: i.content,
              user: "robot"
            });
          }
        });
        if (res.datas.length < 10) {
          this.isShowwel = true;
        }
        this.updatemsg();
        this.isHaveDataForm = yield API.get(
          `api://base/public/v1/chatbots/${this.robotid}/data-collection`,
          {
            params: {
              visitor_id: this.visitorId
            }
          }
        );
        this.controller.visitorId = this.visitorId;
      } else {
        let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
        let maxPos = chars.length;
        var code = "";
        for (let i = 0; i < 6; i++) {
          code += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        let visitorId1 = new Promise((resolve) => {
          setTimeout(function() {
            Fingerprint2.get(function(components) {
              const values = components.map(function(component) {
                return component.value;
              });
              const murmur = Fingerprint2.x64hash128(values.join(""), 31);
              resolve(murmur);
            });
          }, 500);
        });
        visitorId1.then((data) => __async(this, null, function* () {
          localStorage.setItem("visitorId", data + code);
          this.visitorId = data + code;
          this.isShowwel = true;
          this.isHaveDataForm = yield API.get(
            `api://base/public/v1/chatbots/${this.robotid}/data-collection`,
            {
              params: {
                visitor_id: this.visitorId
              }
            }
          );
          this.controller.visitorId = this.visitorId;
        }));
      }
      this.nameRules = [
        {
          required: this.dataformcontroller.visitorNameInputRequired,
          message: "Required",
          trigger: "blur"
        }
      ];
      this.emailRules = [
        {
          required: this.dataformcontroller.visitorEmailInputRequired,
          message: "Required",
          trigger: "blur"
        },
        {
          type: "email",
          message: "Please enter the correct format email",
          trigger: "blur"
        }
      ];
      this.phoneRules = [
        {
          required: this.dataformcontroller.visitorPhoneInputRequired,
          message: "Required",
          trigger: "blur"
        }
      ];
      this.telegramRules = [
        {
          required: this.dataformcontroller.visitorTelegramInputRequired,
          message: "Required",
          trigger: "blur"
        }
      ];
      let robotid = this.robotid;
      let visitorId = this.visitorId;
      let setWebsiteportal = (data) => {
        this.Websiteportal = data;
      };
      window.addEventListener(
        "message",
        function(e) {
          return __async(this, null, function* () {
            if (e.data.type == "open") {
              setWebsiteportal(e.data.data);
              let div = document.getElementById("chat-box");
              div.scrollTop = div.scrollHeight;
              yield API.get(
                `api://base/public/v1/chatbots/${robotid}/click-event`,
                {
                  params: {
                    visitor_id: visitorId
                  }
                }
              );
            }
          });
        },
        false
      );
    });
  }
  saveData() {
    return __async(this, null, function* () {
      yield API.post(
        `api://base/public/v1/chatbots/${this.robotid}/data-collection`,
        this.controller
      );
      this.isShowDataForm = false;
    });
  }
  divscrolling() {
    return __async(this, null, function* () {
      if (this.isShowwel) {
        return;
      }
      let divchat = document.getElementById("chat-box");
      if (divchat.scrollTop == 1) {
        if (this.message_id) {
          let res = yield robotshareApi.getShareChattingRecords(
            window.location.href.slice(
              window.location.href.indexOf("id=") + 3
            ),
            this.visitorId,
            this.message_id
          );
          if (res.datas.length !== 0) {
            this.message_id = res.datas[res.datas.length - 1].id;
            res.datas.forEach((i) => {
              if (i.role == "USER") {
                this.chatRecord.unshift({
                  content: i.content,
                  user: "my"
                });
              } else {
                this.chatRecord.unshift({
                  content: i.content,
                  user: "robot"
                });
              }
            });
            if (res.datas.length < 10) {
              this.isShowwel = true;
              this.$nextTick(() => {
                let x = document.querySelectorAll(
                  `#chat-box>.chat-data:nth-child(${res.datas.length + this.welcomeMessage.length + 1})`
                );
                this.chatboxscrollTop = x[0].offsetTop - x[0].offsetHeight;
                divchat.scrollTop = this.chatboxscrollTop - 3;
              });
            } else {
              this.$nextTick(() => {
                let x = document.querySelectorAll(
                  `#chat-box>.chat-data:nth-child(${res.datas.length + 1})`
                );
                this.chatboxscrollTop = x[0].offsetTop - x[0].offsetHeight;
                divchat.scrollTop = this.chatboxscrollTop - 3;
              });
            }
          } else {
            this.isShowwel = true;
            let x = document.querySelectorAll(
              `#chat-box>.chat-data:nth-child(${this.welcomeMessage.length + 1})`
            );
            this.chatboxscrollTop = x[0].offsetTop - x[0].offsetHeight;
            this.$nextTick(() => {
              divchat.scrollTop = this.chatboxscrollTop;
            });
          }
        }
      }
    });
  }
  updatemsg() {
    this.$nextTick(() => {
      let div = document.getElementById("chat-box");
      div.scrollTop = div.scrollHeight;
    });
  }
  createDataForm() {
    if (this.chatRecord.length == 3) {
      this.isShowDataForm = true;
    }
  }
  //sse
  sseMsg(sendmsg) {
    let opsendmsg = () => {
      this.isPost = true;
      this.getAutomaticPrompt();
    };
    let getssemsg = () => {
      this.chatRecord.push({
        content: "",
        user: "robot"
      });
      this.isloading = false;
    };
    let updatessemsg = (msg) => {
      this.chatRecord[this.chatRecord.length - 1].content += msg;
      this.updatemsg();
    };
    fetchEventSource(
      `https://gateway.kuajingvs.com/chatimport/public/v1/chatbots/${this.robotid}/conversation`,
      {
        method: "POST",
        openWhenHidden: true,
        body: JSON.stringify({
          visitorId: this.visitorId,
          input: sendmsg
        }),
        headers: {
          "Content-Type": "application/json",
          "accept-language": localStorage.getItem("lang"),
          "x-website-portal": this.Websiteportal
        },
        onopen() {
          return __async(this, null, function* () {
            getssemsg();
          });
        },
        onmessage(msg) {
          let msgdata = JSON.parse(msg.data);
          if (msgdata.content !== "[DONE]") {
            updatessemsg(msgdata.content);
          }
        },
        onclose() {
          opsendmsg();
        }
      }
    );
  }
  //获取自动提示词
  getAutomaticPrompt() {
    return __async(this, null, function* () {
      if (!this.automaticPrompt) {
        return false;
      }
      try {
        this.suggestedlist = (yield API.get(
          `api://base/public/v1/chatbots/${this.robotid}/automatic-prompt`,
          {
            params: {
              visitor_id: this.visitorId
            }
          }
        )).data;
      } catch (err) {
        console.log(err);
      }
    });
  }
  //发送快捷建议
  sendSuggest(msg) {
    if (!this.isPost) {
      return;
    }
    this.isPost = false;
    this.isloading = true;
    this.chatRecord.push({
      content: msg,
      user: "my"
    });
    this.updatemsg();
    this.sseMsg(msg);
    this.createDataForm();
    this.updatemsg();
  }
  //用户主动发送的消息
  sendMsg() {
    return __async(this, null, function* () {
      if (!this.isPost) {
        return;
      }
      this.isPost = false;
      this.isloading = true;
      let sendmsg = this.msg;
      this.msg = "";
      this.chatRecord.push({
        content: sendmsg,
        user: "my"
      });
      this.updatemsg();
      this.sseMsg(sendmsg);
      this.createDataForm();
      this.updatemsg();
    });
  }
  //回车发生消息
  suball(event) {
    event = event ? event : window.event ? window.event : "";
    var keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
    if (event.keyCode == 13 && event.ctrlKey) {
      this.msg = this.msg + "\n";
    } else if (keyCode == 13) {
      event.preventDefault();
      this.sendMsg();
    }
  }
  // 关闭弹窗
  close() {
    window.parent.postMessage("close", "*");
  }
};
__decorateClass([
  Ref("dataForm")
], RobotChat.prototype, "_dataForm", 2);
__decorateClass([
  Ref("anchor")
], RobotChat.prototype, "_anchor", 2);
RobotChat = __decorateClass([
  Component({
    components: {
      UiForm
    }
  })
], RobotChat);
export {
  RobotChat as default
};
