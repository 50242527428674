"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import { Message } from "element-ui";
import Layout from "@/components/Layout.vue";
import { robotshareApi } from "../services/robotshare.api";
import { robotsettingsApi } from "../../setting/services/robotsettings.api";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
export class RobotShareCtrl {
  constructor() {
    this.enableShareLink = false;
    this.enableShareIframe = false;
    this.enableShareScript = false;
    this.enableDomains = false;
    this.domains = [];
  }
}
let RobotShare = class extends Vue {
  constructor() {
    super(...arguments);
    this.scriptid = "";
    this.url = "";
    this.robotsharectrl = new RobotShareCtrl();
    this.tooltipdisabled = false;
    this.accountInfo = AccountInfoService.getAccountInfo();
    this.isloading = false;
    this.options = [
      {
        value: "PC_AND_MOBILE",
        label: "PC and Mobile"
      },
      {
        value: "ONLY_PC",
        label: "PC display"
      },
      {
        value: "ONLY_MOBILE",
        label: "Mobile display"
      }
    ];
    this.timezones = [];
    this.tableHeader = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23
    ];
    //   rowUnit = [
    //   [
    //     {
    //       time: 0,
    //       class: "",
    //     },
    //     {
    //       time: 1,
    //       class: "",
    //     },
    //     {
    //       time: 2,
    //       class: "",
    //     },
    //     {
    //       time: 3,
    //       class: "",
    //     },
    //     {
    //       time: 4,
    //       class: "",
    //     },
    //     {
    //       time: 5,
    //       class: "",
    //     },
    //     {
    //       time: 6,
    //       class: "",
    //     },
    //     {
    //       time: 7,
    //       class: "",
    //     },
    //     {
    //       time: 8,
    //       class: "",
    //     },
    //     {
    //       time: 9,
    //       class: "",
    //     },
    //     {
    //       time: 10,
    //       class: "",
    //     },
    //     {
    //       time: 11,
    //       class: "",
    //     },
    //     {
    //       time: 12,
    //       class: "",
    //     },
    //     {
    //       time: 13,
    //       class: "",
    //     },
    //     {
    //       time: 14,
    //       class: "",
    //     },
    //     {
    //       time: 15,
    //       class: "",
    //     },
    //     {
    //       time: 16,
    //       class: "",
    //     },
    //     {
    //       time: 17,
    //       class: "",
    //     },
    //     {
    //       time: 18,
    //       class: "",
    //     },
    //     {
    //       time: 19,
    //       class: "",
    //     },
    //     {
    //       time: 20,
    //       class: "",
    //     },
    //     {
    //       time: 21,
    //       class: "",
    //     },
    //     {
    //       time: 22,
    //       class: "",
    //     },
    //     {
    //       time: 23,
    //       class: "",
    //     },
    //   ],
    //   [
    //     {
    //       time: 0,
    //       class: "",
    //     },
    //     {
    //       time: 1,
    //       class: "",
    //     },
    //     {
    //       time: 2,
    //       class: "",
    //     },
    //     {
    //       time: 3,
    //       class: "",
    //     },
    //     {
    //       time: 4,
    //       class: "",
    //     },
    //     {
    //       time: 5,
    //       class: "",
    //     },
    //     {
    //       time: 6,
    //       class: "",
    //     },
    //     {
    //       time: 7,
    //       class: "",
    //     },
    //     {
    //       time: 8,
    //       class: "",
    //     },
    //     {
    //       time: 9,
    //       class: "",
    //     },
    //     {
    //       time: 10,
    //       class: "",
    //     },
    //     {
    //       time: 11,
    //       class: "",
    //     },
    //     {
    //       time: 12,
    //       class: "",
    //     },
    //     {
    //       time: 13,
    //       class: "",
    //     },
    //     {
    //       time: 14,
    //       class: "",
    //     },
    //     {
    //       time: 15,
    //       class: "",
    //     },
    //     {
    //       time: 16,
    //       class: "",
    //     },
    //     {
    //       time: 17,
    //       class: "",
    //     },
    //     {
    //       time: 18,
    //       class: "",
    //     },
    //     {
    //       time: 19,
    //       class: "",
    //     },
    //     {
    //       time: 20,
    //       class: "",
    //     },
    //     {
    //       time: 21,
    //       class: "",
    //     },
    //     {
    //       time: 22,
    //       class: "",
    //     },
    //     {
    //       time: 23,
    //       class: "",
    //     },
    //   ],
    //   [
    //     {
    //       time: 0,
    //       class: "",
    //     },
    //     {
    //       time: 1,
    //       class: "",
    //     },
    //     {
    //       time: 2,
    //       class: "",
    //     },
    //     {
    //       time: 3,
    //       class: "",
    //     },
    //     {
    //       time: 4,
    //       class: "",
    //     },
    //     {
    //       time: 5,
    //       class: "",
    //     },
    //     {
    //       time: 6,
    //       class: "",
    //     },
    //     {
    //       time: 7,
    //       class: "",
    //     },
    //     {
    //       time: 8,
    //       class: "",
    //     },
    //     {
    //       time: 9,
    //       class: "",
    //     },
    //     {
    //       time: 10,
    //       class: "",
    //     },
    //     {
    //       time: 11,
    //       class: "",
    //     },
    //     {
    //       time: 12,
    //       class: "",
    //     },
    //     {
    //       time: 13,
    //       class: "",
    //     },
    //     {
    //       time: 14,
    //       class: "",
    //     },
    //     {
    //       time: 15,
    //       class: "",
    //     },
    //     {
    //       time: 16,
    //       class: "",
    //     },
    //     {
    //       time: 17,
    //       class: "",
    //     },
    //     {
    //       time: 18,
    //       class: "",
    //     },
    //     {
    //       time: 19,
    //       class: "",
    //     },
    //     {
    //       time: 20,
    //       class: "",
    //     },
    //     {
    //       time: 21,
    //       class: "",
    //     },
    //     {
    //       time: 22,
    //       class: "",
    //     },
    //     {
    //       time: 23,
    //       class: "",
    //     },
    //   ],
    //   [
    //     {
    //       time: 0,
    //       class: "",
    //     },
    //     {
    //       time: 1,
    //       class: "",
    //     },
    //     {
    //       time: 2,
    //       class: "",
    //     },
    //     {
    //       time: 3,
    //       class: "",
    //     },
    //     {
    //       time: 4,
    //       class: "",
    //     },
    //     {
    //       time: 5,
    //       class: "",
    //     },
    //     {
    //       time: 6,
    //       class: "",
    //     },
    //     {
    //       time: 7,
    //       class: "",
    //     },
    //     {
    //       time: 8,
    //       class: "",
    //     },
    //     {
    //       time: 9,
    //       class: "",
    //     },
    //     {
    //       time: 10,
    //       class: "",
    //     },
    //     {
    //       time: 11,
    //       class: "",
    //     },
    //     {
    //       time: 12,
    //       class: "",
    //     },
    //     {
    //       time: 13,
    //       class: "",
    //     },
    //     {
    //       time: 14,
    //       class: "",
    //     },
    //     {
    //       time: 15,
    //       class: "",
    //     },
    //     {
    //       time: 16,
    //       class: "",
    //     },
    //     {
    //       time: 17,
    //       class: "",
    //     },
    //     {
    //       time: 18,
    //       class: "",
    //     },
    //     {
    //       time: 19,
    //       class: "",
    //     },
    //     {
    //       time: 20,
    //       class: "",
    //     },
    //     {
    //       time: 21,
    //       class: "",
    //     },
    //     {
    //       time: 22,
    //       class: "",
    //     },
    //     {
    //       time: 23,
    //       class: "",
    //     },
    //   ],
    //   [
    //     {
    //       time: 0,
    //       class: "",
    //     },
    //     {
    //       time: 1,
    //       class: "",
    //     },
    //     {
    //       time: 2,
    //       class: "",
    //     },
    //     {
    //       time: 3,
    //       class: "",
    //     },
    //     {
    //       time: 4,
    //       class: "",
    //     },
    //     {
    //       time: 5,
    //       class: "",
    //     },
    //     {
    //       time: 6,
    //       class: "",
    //     },
    //     {
    //       time: 7,
    //       class: "",
    //     },
    //     {
    //       time: 8,
    //       class: "",
    //     },
    //     {
    //       time: 9,
    //       class: "",
    //     },
    //     {
    //       time: 10,
    //       class: "",
    //     },
    //     {
    //       time: 11,
    //       class: "",
    //     },
    //     {
    //       time: 12,
    //       class: "",
    //     },
    //     {
    //       time: 13,
    //       class: "",
    //     },
    //     {
    //       time: 14,
    //       class: "",
    //     },
    //     {
    //       time: 15,
    //       class: "",
    //     },
    //     {
    //       time: 16,
    //       class: "",
    //     },
    //     {
    //       time: 17,
    //       class: "",
    //     },
    //     {
    //       time: 18,
    //       class: "",
    //     },
    //     {
    //       time: 19,
    //       class: "",
    //     },
    //     {
    //       time: 20,
    //       class: "",
    //     },
    //     {
    //       time: 21,
    //       class: "",
    //     },
    //     {
    //       time: 22,
    //       class: "",
    //     },
    //     {
    //       time: 23,
    //       class: "",
    //     },
    //   ],
    //   [
    //     {
    //       time: 0,
    //       class: "",
    //     },
    //     {
    //       time: 1,
    //       class: "",
    //     },
    //     {
    //       time: 2,
    //       class: "",
    //     },
    //     {
    //       time: 3,
    //       class: "",
    //     },
    //     {
    //       time: 4,
    //       class: "",
    //     },
    //     {
    //       time: 5,
    //       class: "",
    //     },
    //     {
    //       time: 6,
    //       class: "",
    //     },
    //     {
    //       time: 7,
    //       class: "",
    //     },
    //     {
    //       time: 8,
    //       class: "",
    //     },
    //     {
    //       time: 9,
    //       class: "",
    //     },
    //     {
    //       time: 10,
    //       class: "",
    //     },
    //     {
    //       time: 11,
    //       class: "",
    //     },
    //     {
    //       time: 12,
    //       class: "",
    //     },
    //     {
    //       time: 13,
    //       class: "",
    //     },
    //     {
    //       time: 14,
    //       class: "",
    //     },
    //     {
    //       time: 15,
    //       class: "",
    //     },
    //     {
    //       time: 16,
    //       class: "",
    //     },
    //     {
    //       time: 17,
    //       class: "",
    //     },
    //     {
    //       time: 18,
    //       class: "",
    //     },
    //     {
    //       time: 19,
    //       class: "",
    //     },
    //     {
    //       time: 20,
    //       class: "",
    //     },
    //     {
    //       time: 21,
    //       class: "",
    //     },
    //     {
    //       time: 22,
    //       class: "",
    //     },
    //     {
    //       time: 23,
    //       class: "",
    //     },
    //   ],
    //   [
    //     {
    //       time: 0,
    //       class: "",
    //     },
    //     {
    //       time: 1,
    //       class: "",
    //     },
    //     {
    //       time: 2,
    //       class: "",
    //     },
    //     {
    //       time: 3,
    //       class: "",
    //     },
    //     {
    //       time: 4,
    //       class: "",
    //     },
    //     {
    //       time: 5,
    //       class: "",
    //     },
    //     {
    //       time: 6,
    //       class: "",
    //     },
    //     {
    //       time: 7,
    //       class: "",
    //     },
    //     {
    //       time: 8,
    //       class: "",
    //     },
    //     {
    //       time: 9,
    //       class: "",
    //     },
    //     {
    //       time: 10,
    //       class: "",
    //     },
    //     {
    //       time: 11,
    //       class: "",
    //     },
    //     {
    //       time: 12,
    //       class: "",
    //     },
    //     {
    //       time: 13,
    //       class: "",
    //     },
    //     {
    //       time: 14,
    //       class: "",
    //     },
    //     {
    //       time: 15,
    //       class: "",
    //     },
    //     {
    //       time: 16,
    //       class: "",
    //     },
    //     {
    //       time: 17,
    //       class: "",
    //     },
    //     {
    //       time: 18,
    //       class: "",
    //     },
    //     {
    //       time: 19,
    //       class: "",
    //     },
    //     {
    //       time: 20,
    //       class: "",
    //     },
    //     {
    //       time: 21,
    //       class: "",
    //     },
    //     {
    //       time: 22,
    //       class: "",
    //     },
    //     {
    //       time: 23,
    //       class: "",
    //     },
    //   ],
    // ];
    this.numRows = 7;
    this.numCols = 24;
    //时间0-23小时循环
    this.rowUnit = Array.from(
      { length: this.numRows },
      () => Array.from({ length: this.numCols }, () => ({
        class: ""
      }))
    );
    this.flag = false;
    this.startIndex = 0;
    this.endIndex = 0;
    this.distance = 0;
    this.leaveIndex = 0;
  }
  handleMouseDown(i, index) {
    this.tooltipdisabled = true;
    this.startIndex = i;
    if (this.rowUnit[index][i].class.length == 0) {
      this.rowUnit[index][i].class = "isSelect";
    } else {
      this.rowUnit[index][i].class = "";
    }
    this.flag = true;
  }
  handleMouseUp(i) {
    this.tooltipdisabled = false;
    this.flag = false;
    this.distance = 0;
  }
  handleMouseEnter(i, index) {
    if (!this.flag) {
      return;
    }
    this.endIndex = i;
    let nowdistance = this.distance;
    this.distance = Math.abs(this.endIndex - this.startIndex);
    if (this.distance < nowdistance) {
      this.rowUnit[index][this.leaveIndex].class = "";
    }
    if (this.rowUnit[index][i].class.length == 0) {
      this.rowUnit[index][i].class = "isSelect";
    } else {
      this.rowUnit[index][i].class = "";
    }
  }
  handleMouseLeave(i, index) {
    if (!this.flag) {
      return;
    }
    this.leaveIndex = i;
  }
  mounted() {
    return __async(this, null, function* () {
      this.scriptid = (yield robotsettingsApi.getGeneral(this.$route.query.id)).shareId;
      this.robotsharectrl = yield robotshareApi.getShare(
        this.$route.query.id
      );
      if (this.robotsharectrl.domains) {
        this.robotsharectrl.domains.forEach((item) => {
          this.url += `${item}
`;
        });
      }
      this.robotsharectrl.advancedBubbleSetting.displayTimeRange.forEach(
        (item) => {
          item.hours.forEach((hour) => {
            this.rowUnit[item.dayOfWeek - 1][hour].class = "isSelect";
          });
        }
      );
      this.robotsharectrl.advancedBubbleSetting.timeZone = (yield robotshareApi.getTimezone(this.$route.query.id, {
        timezone_identifier: Intl.DateTimeFormat().resolvedOptions().timeZone
      })).timeZone;
      let list = (yield robotshareApi.getTimezoneList(this.$route.query.id)).datas;
      list.forEach((item) => {
        this.timezones.push({
          value: item,
          label: item
        });
      });
    });
  }
  tooltipTip(item, index, weekindex) {
    let arr = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];
    if (item.class.length > 0) {
      return `${arr[weekindex]} ${index}:00 - ${index + 1}:00 display`;
    } else {
      return `${arr[weekindex]} ${index}:00 - ${index + 1}:00 no display`;
    }
  }
  reDisplayTime() {
    return __async(this, null, function* () {
      this.rowUnit = Array.from(
        { length: this.numRows },
        () => Array.from({ length: this.numCols }, () => ({
          class: ""
        }))
      );
      this.robotsharectrl.advancedBubbleSetting.displayTimeRange = (yield robotsettingsApi.reset(this.$route.query.id, {
        reset_type: "SHARE_ADVANCED_BUBBLE_DISPLAY_TIME"
      })).displayTimeRange;
      this.robotsharectrl.advancedBubbleSetting.displayTimeRange.forEach(
        (item) => {
          item.hours.forEach((hour) => {
            this.rowUnit[item.dayOfWeek - 1][hour].class = "isSelect";
          });
        }
      );
    });
  }
  saveshare() {
    return __async(this, null, function* () {
      this.isloading = true;
      let urlArray;
      urlArray = this.url.trim().replaceAll(" ", "").split("\n");
      this.robotsharectrl.advancedBubbleSetting.displayTimeRange = [];
      this.robotsharectrl.domains = urlArray;
      if (this.url.length == 0) {
        this.robotsharectrl.domains = [];
      }
      this.rowUnit.forEach((item, index) => {
        let arr = {
          dayOfWeek: index + 1,
          hours: []
        };
        let count = 0;
        item.forEach((item2, index2) => {
          if (item2.class.length > 0) {
            count++;
            arr.hours.push(index2);
          }
        });
        if (count) {
          this.robotsharectrl.advancedBubbleSetting.displayTimeRange.push(arr);
        }
      });
      yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/share`,
        this.robotsharectrl
      ).then(() => {
        this.isloading = false;
        this.robotsharectrl.advancedBubbleSetting.displayTimeRange = [];
        Message.success(this.$t("route.success"));
      }).catch((err) => {
        this.isloading = false;
        throwErrorApi.throwError(err);
      });
    });
  }
  copyDivlinkbox() {
    navigator.clipboard.writeText(`https://chatimport.com/independent?id=${this.scriptid}`).then(() => {
      Message.success(this.$t("route.success"));
    });
  }
  copyDiviframebox() {
    navigator.clipboard.writeText(
      `<iframe
src="https://chatimport.com/independent?id=${this.scriptid}"
width="100%"
style="height: 100%; min-height: 700px;"
frameborder="0"
></iframe>`
    ).then(() => {
      Message.success(this.$t("route.success"));
    });
  }
  copyDivscriptbox() {
    navigator.clipboard.writeText(
      `<script
src="https://chatimport.com/js/implant.js" 
id="${this.scriptid}"
defer
><\/script>`
    ).then(() => {
      Message.success(this.$t("route.success"));
    });
  }
  clearTime() {
    this.rowUnit = Array.from(
      { length: this.numRows },
      () => Array.from({ length: this.numCols }, () => ({
        class: ""
      }))
    );
  }
};
RobotShare = __decorateClass([
  Component({
    components: {
      Layout
    }
  })
], RobotShare);
export {
  RobotShare as default
};
