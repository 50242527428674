"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import { dashboardapi } from "../../services/dashboard.api";
let ShowSources = class extends Vue {
  constructor() {
    super(...arguments);
    // 是否显示弹窗
    this.dialogVisible = false;
    this.sourceData = {};
    this.source = "";
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.dialogVisible = true;
      this.$nextTick(() => __async(this, null, function* () {
        let resdata = (yield dashboardapi.getOriginalCorpus(
          this.$route.query.id,
          this.showsourcesData.message_id
        )).datas;
        if (resdata.length == 0) {
          this.source = "none";
          this.sourceData.content = "none";
        } else {
          this.sourceData = resdata[0];
          if (this.sourceData.chatBotTrainCorpusResponse.trainCorpusType === "FILE") {
            this.source = "File - " + this.sourceData.chatBotTrainCorpusResponse.name;
          } else if (this.sourceData.chatBotTrainCorpusResponse.trainCorpusType === "TEXT") {
            this.source = "Text";
          } else if (this.sourceData.chatBotTrainCorpusResponse.trainCorpusType === "LINK") {
            this.source = "Link - " + this.sourceData.chatBotTrainCorpusResponse.url;
          } else {
            this.source = "Q&A - " + this.sourceData.chatBotTrainCorpusResponse.question;
          }
        }
      }));
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
};
__decorateClass([
  Prop()
], ShowSources.prototype, "showsourcesData", 2);
__decorateClass([
  Ref("Form")
], ShowSources.prototype, "_form", 2);
ShowSources = __decorateClass([
  Component({
    components: {}
  })
], ShowSources);
export {
  ShowSources as default
};
