"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class analyticsApi {
  //获取机器人分析数据
  getAnalytics(id, params) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/analytics`,
        {
          params
        }
      );
      return response.data;
    });
  }
  //获取机器人城市聊天分析数据country-chats
  getCountrychats(id, params) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/analytics/country-chats`,
        {
          params
        }
      );
      return response.data;
    });
  }
}
export const analyticsapi = new analyticsApi();
