"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import RobotCreateDialog from "./dialog/RobotCreateDialog.vue";
import { robotsettingInfoAPI } from "@/shared/api/robot-info/robot-setting-info.api";
import RobotChat from "./dialog/RobotChat.vue";
import API from "@ym-framework/api-utils";
import { robotsettingsApi } from "../../setting/services/robotsettings.api";
import { accountBindAPI } from "@/shared/api/account-bind/account-bind.api";
import { Message } from "element-ui";
let Home = class extends Vue {
  constructor() {
    super(...arguments);
    this.robotlist = [];
    this.reqstatus = true;
    this.chatbotlimit = "";
  }
  mounted() {
    return __async(this, null, function* () {
      this.$store.commit(
        "userpackage/updatenowcombos",
        yield accountBindAPI.getCombos()
      );
      this.chatbotlimit = (yield robotsettingInfoAPI.getChatbotsLimit()).value;
      this.robotlist = (yield API.get(`api://base.auth/v1/chatbots`, {
        params: { size: 50, page: 1 }
      })).data.result.datas;
      this.reqstatus = false;
    });
  }
  opendialog() {
    return __async(this, null, function* () {
      this._robotcreatedialog.open(this.robotlist.length + 1);
    });
  }
  //添加成功后加载页面
  addQaLoading() {
    return __async(this, null, function* () {
      this.reqstatus = true;
      this.robotlist = (yield API.get(`api://base.auth/v1/chatbots`, {
        params: { size: 50, page: 1 }
      })).data.result.datas;
      this.reqstatus = false;
    });
  }
  delchatbot(id) {
    return __async(this, null, function* () {
      this.reqstatus = true;
      yield API.delete(`api://base.auth/v1/chatbots/${id}`).then(() => {
        Message.success(this.$t("settings.generl.success_delete"));
      });
      this.robotlist = (yield API.get(`api://base.auth/v1/chatbots`, {
        params: { size: 50, page: 1 }
      })).data.result.datas;
      this.reqstatus = false;
    });
  }
  goToDetail(id, botname) {
    return __async(this, null, function* () {
      this.$store.commit("chatbot/getnowbotname", botname);
      this.$store.commit(
        "chatbot/getshareId",
        (yield robotsettingsApi.getGeneral(id)).shareId
      );
      this.$router.push({
        path: "/chatbot",
        query: {
          id
        }
      });
    });
  }
  rename(id, botname) {
    return __async(this, null, function* () {
      this.$store.commit("chatbot/getnowbotname", botname);
      this.$store.commit(
        "chatbot/getshareId",
        (yield robotsettingsApi.getGeneral(id)).shareId
      );
      this.$router.push({
        path: "/settings",
        query: {
          id
        }
      });
    });
  }
  opendel(id, name) {
    return __async(this, null, function* () {
      this.$confirm(
        `${this.$t("home.tips")}${name}${this.$t("home.tips2")}`,
        this.$t("home.tip"),
        {
          confirmButtonText: this.$t("detail.delete"),
          cancelButtonText: this.$t("components.message_box.cancel"),
          type: "warning"
        }
      ).then(() => {
        this.delchatbot(id);
      }).catch(() => {
      });
    });
  }
};
__decorateClass([
  Ref("robotcreatedialog")
], Home.prototype, "_robotcreatedialog", 2);
__decorateClass([
  Ref("robotchat")
], Home.prototype, "_robotchat", 2);
Home = __decorateClass([
  Component({
    components: {
      Layout,
      RobotCreateDialog,
      RobotChat
    }
  })
], Home);
export {
  Home as default
};
