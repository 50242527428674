"use strict";
import i18n from "@/lang";
export class RegisterService {
  /**
   * 校验两次密码是否一致
   * @param confirmPassword
   * @param password
   * @param callback
   */
  checkConfirmPassword(confirmPassword, password, callback) {
    if (confirmPassword && confirmPassword !== password) {
      callback(new Error(i18n.t("auth.info.password_do_not_match")));
      return false;
    }
    callback();
    return true;
  }
}
