"use strict";
export class LoginService {
  /**
   * 修改是否记住密码
   * @param remember
   */
  changeRememberPw(remember) {
    if (!remember) {
      localStorage.removeItem("_remember_pw");
    }
  }
}
