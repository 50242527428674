"use strict";
import date_format from "date-and-time";
import i18n from "@/lang";
export default {
  check: (time) => {
    const _time = String(time);
    if (_time.length > 10) {
      return time;
    }
    time = time * 1e3;
    return time;
  },
  date: (time) => {
    const date = new Date();
    date.setTime(time);
    return date.toLocaleDateString();
  },
  dateTime: (time) => {
    const date = new Date();
    date.setTime(time);
    return date.toLocaleString();
  },
  last: (time) => {
    return {
      day: () => {
        return Math.ceil(time / 86400);
      }
    };
  },
  dateformatterDay: (time) => {
    return date_format.format(new Date(time), "YYYY-MM-DD");
  },
  dateformat: (time) => {
    return date_format.format(new Date(time), "YYYY/MM/DD HH:mm:ss");
  },
  dateTimeFormat: (time) => {
    return date_format.format(new Date(time), "MM/DD HH:mm");
  },
  dateformatDay: (time) => {
    return date_format.format(new Date(time), "YYYY/MM/DD");
  },
  format_login_time: (time) => {
    let _time = Date.now() - time;
    if (_time < 6e4) {
      return i18n.t("timefomatter.seconds_ago");
    }
    if (_time < 36e5) {
      _time = Math.floor(_time / (1e3 * 60));
      return i18n.t("timefomatter.minites_ago", { time: _time });
    }
    if (_time < 864e5) {
      _time = Math.floor(_time / (1e3 * 60 * 60));
      return i18n.t("timefomatter.hours_ago", { time: _time });
    }
    if (i18n.locale === "zh-CN") {
      return date_format.format(new Date(time), "M-DD");
    }
    if (i18n.locale === "en-US") {
      return date_format.format(new Date(time), "DD MMM");
    }
    return date_format.format(new Date(time), "DD MMM");
  }
};
