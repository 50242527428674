"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import builder from "@rsql/builder";
import { emit } from "@rsql/emitter";
import API from "@ym-framework/api-utils";
import RobotAddQa from "../dialog/RobotAddQa.vue";
import RobotChangeQa from "../dialog/RobotChangeQa.vue";
import RobotExportQa from "../dialog/RobotExportQa.vue";
import RobotUploadQa from "../dialog/RobotUploadQa.vue";
import { robottrainAPI } from "../../services/robottrain.api";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
let RobotQA = class extends Vue {
  constructor() {
    super(...arguments);
    this.rsql = emit(builder.and(builder.comparison("createdTime", "=sort=", "desc")));
    this.gridController = new GridController(
      new APIDataSource(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/qa`
      ),
      { search: this.rsql }
    );
    // 选中的列表行对象数组
    this.robotqaSelection = [];
    //编辑QA的数据
    this.beforeChangeData = {};
    this.usedCharacters = 0;
    this.Requesting = false;
  }
  // expression = parse("createdTime");
  mounted() {
    return __async(this, null, function* () {
      this.usedCharacters = yield robottrainAPI.getusedCharacters(
        this.$route.query.id
      );
    });
  }
  reloadPart() {
    return __async(this, null, function* () {
      this.usedCharacters = yield robottrainAPI.getusedCharacters(
        this.$route.query.id
      );
      robottrainAPI.getUsed(this.$route.query.id);
      yield this.gridController.search();
      this.Requesting = false;
    });
  }
  // 获取选中的列表
  handleSelectionChange(val) {
    this.robotqaSelection = val;
  }
  closeselect() {
    this._table.clearSelection();
  }
  //添加问题弹窗
  openAddQa() {
    this._robotaddqa.open();
  }
  //导出qa
  exportqa() {
    this._robotexportqa.open();
  }
  //批量导入qa
  uploadqa() {
    this._robotuploadqa.open();
  }
  //编辑QA
  changeqa(data) {
    this.beforeChangeData = {
      question: data.question,
      answer: data.answer,
      id: data.id,
      files: data.files
    };
    setTimeout(() => {
      this._robotchangeqa.open();
    }, 0);
  }
  //批量删除qa
  delQas(type, id) {
    return __async(this, null, function* () {
      if (this.Requesting) {
        return 0;
      }
      this.Requesting = true;
      let delall = new Array();
      if (type == "deleteall") {
        this.robotqaSelection.forEach((i) => {
          delall.push(i.id);
        });
      } else {
        delall.push(id);
      }
      try {
        yield API.delete(
          `api://base.auth/v1/chatbots/${this.$route.query.id}/train/qa`,
          {
            data: {
              textIds: delall
            }
          }
        );
        this.$message.success(
          this.$t("settings.generl.success_delete")
        );
        this.robotqaSelection = [];
        this.reloadPart();
      } catch (err) {
        throwErrorApi.throwError(err);
      }
    });
  }
};
__decorateClass([
  Ref("table")
], RobotQA.prototype, "_table", 2);
__decorateClass([
  Ref("robotaddqa")
], RobotQA.prototype, "_robotaddqa", 2);
__decorateClass([
  Ref("robotchangeqa")
], RobotQA.prototype, "_robotchangeqa", 2);
__decorateClass([
  Ref("robotexportqa")
], RobotQA.prototype, "_robotexportqa", 2);
__decorateClass([
  Ref("robotuploadqa")
], RobotQA.prototype, "_robotuploadqa", 2);
RobotQA = __decorateClass([
  Component({
    components: {
      UiGrid,
      RobotAddQa,
      RobotChangeQa,
      RobotExportQa,
      RobotUploadQa
    }
  })
], RobotQA);
export {
  RobotQA as default
};
