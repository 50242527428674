"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
export let AccountAuthModel = class extends CoreModel {
  constructor(auth_id, password) {
    super();
    // auth_id: String = null;
    this.account = null;
    this.password = null;
    if (auth_id) {
      this.account = auth_id;
    }
    if (password) {
      this.password = password;
    }
  }
};
AccountAuthModel.model = "account.auth";
AccountAuthModel = __decorateClass([
  Annotation.ModelMeta
], AccountAuthModel);
export let PhoneAuthModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.auth_id = null;
    this.code = null;
  }
};
PhoneAuthModel.model = "account.auth";
PhoneAuthModel = __decorateClass([
  Annotation.ModelMeta
], PhoneAuthModel);
export let RegisterAuthModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.auth_id = null;
    this.code = null;
    this.password = null;
  }
};
RegisterAuthModel.model = "account.auth";
RegisterAuthModel = __decorateClass([
  Annotation.ModelMeta
], RegisterAuthModel);
export let AddStudentModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.id = null;
    this.name = null;
    this.age = null;
    this.address = null;
  }
};
AddStudentModel.model = "student.add";
AddStudentModel = __decorateClass([
  Annotation.ModelMeta
], AddStudentModel);
