"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import builder from "@rsql/builder";
import { emit } from "@rsql/emitter";
import API from "@ym-framework/api-utils";
let Leads = class extends Vue {
  constructor() {
    super(...arguments);
    this.rsql = emit(builder.and(builder.comparison("createdTime", "=sort=", "desc")));
    this.Requesting = false;
    this.gridController = new GridController(
      new APIDataSource(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/leads`
      )
    );
    // 选中的列表行对象数组
    this.robotqaSelection = [];
    //编辑QA的数据
    this.beforeChangeDara = {};
    this.usedCharacters = 0;
  }
  // expression = parse("createdTime");
  // //局部刷新
  // isReloadData = true;
  // async reloadPart() {
  //   this.isReloadData = false;
  //   this.$nextTick(() => {
  //     this.isReloadData = true;
  //   });
  // }
  // 获取选中的列表
  handleSelectionChange(val) {
    this.robotqaSelection = val;
  }
  closeselect() {
    this._table.clearSelection();
  }
  //批量删除
  deleteData(type, id) {
    return __async(this, null, function* () {
      if (this.Requesting) {
        return;
      }
      this.Requesting = true;
      let delall = new Array();
      if (type == "deleteall") {
        this.robotqaSelection.forEach((i) => {
          delall.push(i.id);
        });
      } else {
        delall.push(id);
      }
      try {
        yield API.post(`api://base.auth/v1/chatbots/bulk-del-leads`, {
          leadIds: delall
        });
        this.robotqaSelection = [];
        yield this.gridController.search();
        this.$message.success(
          this.$t("settings.generl.success_delete")
        );
        this.Requesting = false;
      } catch (error) {
        this.$message.error(this.$t("settings.generl.delete_error"));
        this.Requesting = false;
      }
    });
  }
};
__decorateClass([
  Ref("table")
], Leads.prototype, "_table", 2);
Leads = __decorateClass([
  Component({
    components: {
      UiGrid
    }
  })
], Leads);
export {
  Leads as default
};
