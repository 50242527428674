"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import { Message } from "element-ui";
import UiSubmit from "@/components/form/UiSubmit.vue";
import { robotsettingsApi } from "../../services/robotsettings.api";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.model = "";
    this.failureMessage = "";
    this.sessionExceedsSeconds = 3;
    this.profilePicture = {
      enableProfilePicture: false,
      profilePicture: null
    };
    this.icon = {
      enableIcon: false,
      icon: null
    };
    this.theme = "";
    this.displayName = "";
    this.userMessageColor = "";
    this.bubbleButton = {
      bubbleButtonColor: "",
      alignBubbleButton: "",
      bottomBubbleButtonSize: 30,
      alignBubbleButtonSize: 30
    };
    this.removableWatermark = false;
    this.themeSetting = {
      fontSize: "",
      backgroundColor: "",
      botMessageFontColor: "",
      botMessageColor: "",
      userMessageFontColor: "",
      userMessageColor: "",
      suggestMessageFontSize: ""
    };
    this.enableAutomaticPrompt = false;
  }
}
let ChatInterface = class extends Vue {
  constructor() {
    super(...arguments);
    this.accountInfo = AccountInfoService.getAccountInfo();
    this.alignBubbleButtonbottom = "bottom";
    this.welcomeMessage = "";
    this.suggested = "";
    //用户输入框
    this.msg = "";
    //聊天记录
    this.chatRecord = [{}];
    //移除水印
    this.removableWatermark = false;
    this.themeStyle = {
      "--fontSize": "14",
      "--suggestFontSize": "12",
      "--bubbleButtonColor": "rgb(0, 0, 0,0)",
      "--bgcolor": "rgb(0, 0, 0,0)",
      "--titlecolor": "rgb(0, 0, 0,0)",
      "--robotboxcolor": "rgb(0, 0, 0,0)",
      "--robotdatacolor": "rgb(0, 0, 0,0)",
      "--userboxcolor": "rgb(0, 0, 0,0)",
      "--userdatacolor": "rgb(0, 0, 0,0)",
      "--suggestboxcolor": "rgb(0, 0, 0,0)",
      "--suggestdatacolor": "rgb(0, 0, 0,0)",
      "--inputbgcolor": "rgb(0, 0, 0,0)",
      "--inputbordercolor": "rgb(0, 0, 0,0)",
      "--inputcolor": "rgb(0, 0, 0,0)",
      "--sendbtncolor": "rgb(0, 0, 0,0)"
    };
    this.options = [
      {
        value: "RIGHT",
        label: "Right"
      },
      {
        value: "LEFT",
        label: "Left"
      }
    ];
    this.ftsize = "14px";
    this.color = "#FFFFFF";
    this.predefineColors = [
      "#FF4500",
      "#FF8C00",
      "#FFD700",
      "#90EE90",
      "#00CED1",
      "#1E90FF",
      "#C71585"
    ];
    this.ftsizeoptions = [
      {
        value: "16px",
        label: "16"
      },
      {
        value: "15px",
        label: "15"
      },
      {
        value: "14px",
        label: "14"
      },
      {
        value: "13px",
        label: "13"
      },
      {
        value: "12px",
        label: "12"
      }
    ];
    this.robotsettingdata = {};
    // 创建机器人设置表单controller
    this.controller = new FormController();
  }
  welcomeMessageChanged(val, oldVal) {
    this.welcomeMessagelist = this.welcomeMessage.trim().split("\n");
  }
  suggestedChanged(val, oldVal) {
    this.suggestedlist = this.suggested.trim().split("\n");
  }
  themedChanged(val, oldVal) {
    if (val == "LIGHT") {
      this.themeStyle = {
        "--fontSize": this.themeStyle["--fontSize"],
        "--suggestFontSize": this.themeStyle["--suggestFontSize"],
        "--bubbleButtonColor": "#000000",
        "--bgcolor": "#FFFFFF",
        "--titlecolor": "#000000",
        "--robotboxcolor": "#F7F7F7",
        "--robotdatacolor": "#000000",
        "--userboxcolor": "#000000",
        "--userdatacolor": "#FFFFFF",
        "--suggestboxcolor": "#F7F7F7",
        "--suggestdatacolor": "#000000",
        "--inputbgcolor": "#FFFFFF",
        "--inputbordercolor": "#DADDE5",
        "--inputcolor": "#000000",
        "--sendbtncolor": "#000000"
      };
    } else {
      this.themeStyle = {
        "--fontSize": this.themeStyle["--fontSize"],
        "--suggestFontSize": this.themeStyle["--suggestFontSize"],
        "--bubbleButtonColor": "#000000",
        "--bgcolor": "#000000",
        "--titlecolor": "#FFFFFF",
        "--robotboxcolor": "#333333",
        "--robotdatacolor": "#FFFFFF",
        "--userboxcolor": "#186AFF",
        "--userdatacolor": "#FFFFFF",
        "--suggestboxcolor": "#333333",
        "--suggestdatacolor": "#FFFFFF",
        "--inputbgcolor": "#000000",
        "--inputbordercolor": "#FFFFFF",
        "--inputcolor": "#FFFFFF",
        "--sendbtncolor": "#186AFF"
      };
    }
  }
  mounted() {
    return __async(this, null, function* () {
      let obj = yield robotsettingsApi.getInterface(
        this.$route.query.id
      );
      this.nowData = obj;
      Object.keys(obj).forEach((key) => {
        this.controller[key] = obj[key];
      });
      this.iconUrl = this.controller.icon.icon;
      this.pictureUrl = this.controller.profilePicture.profilePicture;
      if (this.controller.welcomeMessage) {
        this.controller.welcomeMessage.forEach((item) => {
          this.welcomeMessage += `${item}
`;
        });
      }
      if (this.controller.suggestedPrompts) {
        this.controller.suggestedPrompts.forEach((item) => {
          this.suggested += `${item}
`;
        });
      }
      this.$nextTick(() => {
        this.themeStyle = {
          "--fontSize": this.controller.themeSetting.fontSize,
          "--suggestFontSize": this.controller.themeSetting.suggestMessageFontSize,
          "--bubbleButtonColor": this.controller.bubbleButton.bubbleButtonColor,
          "--bgcolor": this.controller.themeSetting.backgroundColor,
          "--titlecolor": this.controller.theme === "LIGHT" ? "#000000" : "#FFFFFF",
          "--robotboxcolor": this.controller.themeSetting.botMessageColor,
          "--robotdatacolor": this.controller.themeSetting.botMessageFontColor,
          "--userboxcolor": this.controller.themeSetting.userMessageColor,
          "--userdatacolor": this.controller.themeSetting.userMessageFontColor,
          "--suggestboxcolor": this.controller.themeSetting.botMessageColor,
          "--suggestdatacolor": this.controller.themeSetting.botMessageFontColor,
          "--inputbgcolor": this.controller.theme === "LIGHT" ? "#FFFFFF" : "#000000",
          "--inputbordercolor": this.controller.theme === "LIGHT" ? "#DADDE5" : "#FFFFFF",
          "--inputcolor": this.controller.theme === "LIGHT" ? "#000000" : "#FFFFFF",
          "--sendbtncolor": this.controller.theme === "LIGHT" ? "#000000" : "#186AFF"
        };
      });
    });
  }
  reWelcomeMessage() {
    return __async(this, null, function* () {
      this.welcomeMessage = (yield robotsettingsApi.reset(this.$route.query.id, {
        reset_type: "INTERFACE_INITIAL_MESSAGE"
      })).message;
    });
  }
  reSuggested() {
    this.suggested = "";
  }
  rePosition() {
    return __async(this, null, function* () {
      let obj = yield robotsettingsApi.reset(this.$route.query.id, {
        reset_type: "INTERFACE_BUBBLE_POSITION"
      });
      this.controller.bubbleButton.alignBubbleButton = obj.alignBubbleButton;
      this.controller.bubbleButton.alignBubbleButtonSize = obj.alignBubbleButtonSize;
      this.controller.bubbleButton.bottomBubbleButtonSize = obj.bottomBubbleButtonSize;
    });
  }
  reTheme() {
    return __async(this, null, function* () {
      let obj = yield robotsettingsApi.reset(this.$route.query.id, {
        reset_type: "INTERFACE_THEME_COLOR",
        theme: this.controller.theme
      });
      this.themeStyle = {
        "--fontSize": obj.fontSize,
        "--suggestFontSize": obj.suggestMessageFontSize,
        "--bubbleButtonColor": obj.bubbleColor,
        "--bgcolor": obj.backgroundColor,
        "--titlecolor": obj.theme === "LIGHT" ? "#000000" : "#FFFFFF",
        "--robotboxcolor": obj.botMessageColor,
        "--robotdatacolor": obj.botMessageFontColor,
        "--userboxcolor": obj.userMessageColor,
        "--userdatacolor": obj.userMessageFontColor,
        "--suggestboxcolor": obj.botMessageColor,
        "--suggestdatacolor": obj.botMessageFontColor,
        "--inputbgcolor": obj.theme === "LIGHT" ? "#FFFFFF" : "#000000",
        "--inputbordercolor": obj.theme === "LIGHT" ? "#DADDE5" : "#FFFFFF",
        "--inputcolor": obj.theme === "LIGHT" ? "#000000" : "#FFFFFF",
        "--sendbtncolor": obj.theme === "LIGHT" ? "#000000" : "#186AFF"
      };
    });
  }
  iconchangefile(file, fileList) {
    if (fileList.length > 1) {
      fileList = fileList.splice(0, 1);
    }
    this.icon = file;
    this.controller.icon.enableIcon = !this.controller.icon.enableIcon;
    this.iconUrl = URL.createObjectURL(file.raw);
    this.controller.icon.enableIcon = !this.controller.icon.enableIcon;
  }
  picturechangefile(file, fileList) {
    if (fileList.length > 1) {
      fileList = fileList.splice(0, 1);
    }
    this.picture = file;
    this.controller.profilePicture.enableProfilePicture = !this.controller.profilePicture.enableProfilePicture;
    this.pictureUrl = URL.createObjectURL(file.raw);
    this.controller.profilePicture.enableProfilePicture = !this.controller.profilePicture.enableProfilePicture;
  }
  submit() {
    return __async(this, null, function* () {
      let urlArray;
      let urlArray1;
      urlArray = this.welcomeMessage.trim().split("\n");
      urlArray1 = this.suggested.trim().split("\n");
      this.controller.welcomeMessage = urlArray;
      this.controller.suggestedPrompts = urlArray1;
      this.controller.bubbleButton.bubbleButtonColor = this.themeStyle["--bubbleButtonColor"];
      this.controller.themeSetting = {
        fontSize: this.themeStyle["--fontSize"],
        backgroundColor: this.themeStyle["--bgcolor"],
        botMessageFontColor: this.themeStyle["--robotdatacolor"],
        botMessageColor: this.themeStyle["--robotboxcolor"],
        userMessageFontColor: this.themeStyle["--userdatacolor"],
        userMessageColor: this.themeStyle["--userboxcolor"],
        suggestMessageFontSize: this.themeStyle["--suggestFontSize"]
      };
      if (this.suggested.length == 0) {
        this.controller.suggestedPrompts = [];
      }
      let fileFormData = new FormData();
      if (this.icon) {
        fileFormData.append("icon", this.icon.raw);
      }
      if (this.picture) {
        fileFormData.append("profile_picture", this.picture.raw);
      }
      fileFormData.append(
        "interface_setting",
        new Blob([JSON.stringify(this.controller)], { type: "application/json" })
      );
      try {
        this.controller.status = UiFormStatus.LOADING;
        yield API.put(
          `api://base.auth/v1/chatbots/${this.$route.query.id}/setting/interface`,
          fileFormData
        ).then(() => __async(this, null, function* () {
          this.nowData = yield robotsettingsApi.getInterface(
            this.$route.query.id
          );
          Message.success(this.$t("settings.generl.success"));
        })).catch((err) => {
          throwErrorApi.throwError(err);
        });
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
};
__decorateClass([
  Ref("Form")
], ChatInterface.prototype, "_form", 2);
__decorateClass([
  Watch("welcomeMessage", { immediate: true, deep: true })
], ChatInterface.prototype, "welcomeMessageChanged", 1);
__decorateClass([
  Watch("suggested", { immediate: true, deep: true })
], ChatInterface.prototype, "suggestedChanged", 1);
__decorateClass([
  Watch("controller.theme", { deep: true })
], ChatInterface.prototype, "themedChanged", 1);
ChatInterface = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], ChatInterface);
export {
  ChatInterface as default
};
