"use strict";
export class AccountInfoService {
  /**
   * 设置用户信息
   * @param accountInfo
   */
  static setAccountInfo(accountInfo) {
    this.accountInfo = accountInfo;
  }
  /// 获取用户信息
  static getAccountInfo() {
    return this.accountInfo;
  }
}
