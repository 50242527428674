"use strict";
import i18n from "@/lang";
export class ForgetPasswordService {
  /**
   * 校验两次密码是否一致
   * @param repeatPassword
   * @param password
   * @param callback
   */
  checkRepeatPassword(repeatPassword, password, callback) {
    if (repeatPassword && repeatPassword !== password) {
      callback(new Error(i18n.t("auth.info.password_do_not_match")));
      return false;
    }
    callback();
    return true;
  }
}
