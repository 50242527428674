"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import { robottrainAPI } from "../../services/robottrain.api";
import API from "@ym-framework/api-utils";
import { Message } from "element-ui";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
let RobotText = class extends Vue {
  constructor() {
    super(...arguments);
    this.text = "";
    this.isloading = false;
  }
  mounted() {
    return __async(this, null, function* () {
      var _a;
      this.text = (_a = (yield robottrainAPI.getText(this.$route.query.id)).result.datas[0]) == null ? void 0 : _a.content;
    });
  }
  //保存text
  savetext() {
    return __async(this, null, function* () {
      this.isloading = true;
      yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/text
`,
        { content: this.text }
      ).then(() => {
        this.isloading = false;
        Message.success(this.$t("settings.generl.success"));
        robottrainAPI.getUsed(this.$route.query.id);
      }).catch((err) => {
        this.isloading = false;
        throwErrorApi.throwError(err);
      });
    });
  }
};
RobotText = __decorateClass([
  Component({
    components: {}
  })
], RobotText);
export {
  RobotText as default
};
