"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import UiForm, {
  UiFormController
} from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.linkType = "";
  }
}
let WebsiteDialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.url = "";
    // 创建表单controller
    this.controller = new FormController();
    // 是否显示弹窗
    this.dialogVisible = false;
  }
  submit() {
    return __async(this, null, function* () {
      let urlArray;
      this.controller.linkType = this.dialogobj.linkType;
      urlArray = this.url.trim().replaceAll(" ", "").split("\n");
      this.controller.urls = Array.from(new Set(urlArray));
      this.close();
      if (this.dialogobj.linkType == "WEBSITE_URL") {
        this.$emit("openprogress");
      }
      yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/website`,
        this.controller
      ).then(() => {
        if (this.dialogobj.linkType == "WEBSITE_URL") {
          this.$store.commit("progress/update", 100);
        }
      }).catch((err) => {
        throwErrorApi.throwError(err);
      });
      this.$emit("getlist");
    });
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
};
__decorateClass([
  Prop(Object)
], WebsiteDialog.prototype, "dialogobj", 2);
__decorateClass([
  Ref("Form")
], WebsiteDialog.prototype, "_form", 2);
WebsiteDialog = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], WebsiteDialog);
export {
  WebsiteDialog as default
};
