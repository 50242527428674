"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, PropSync, Vue } from "vue-property-decorator";
import { InputPasswordService } from "@/shared/components/input-password/services/input-password.service";
let InputPassword = class extends Vue {
  constructor() {
    super(...arguments);
    this.inputPasswordService = new InputPasswordService();
  }
  changeStatus() {
    this.inputPasswordService.changeStatus();
  }
};
__decorateClass([
  PropSync("value", { type: String, default: "" })
], InputPassword.prototype, "_password", 2);
__decorateClass([
  PropSync("type", { type: String, default: "" })
], InputPassword.prototype, "_type", 2);
InputPassword = __decorateClass([
  Component({
    components: {}
  })
], InputPassword);
export {
  InputPassword as default
};
