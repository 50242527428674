"use strict";
export class InputPasswordService {
  constructor() {
    /// 是否隐藏
    this.hidePassword = true;
  }
  /**
   * 修改密码状态
   */
  changeStatus() {
    this.hidePassword = !this.hidePassword;
  }
}
