"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import {
  Component,
  Ref,
  Vue,
  Inject
} from "vue-property-decorator";
import menu from "@/shared/menu-config";
import { authAPI } from "@/data/api/auth/auth.api";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import { robotsettingInfoAPI } from "@/shared/api/robot-info/robot-setting-info.api";
import { accountBindAPI } from "@/shared/api/account-bind/account-bind.api";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { Debounce } from "vue-debounce-decorator";
import Account from "../views/home/views/dialog/Account.vue";
import API from "@ym-framework/api-utils";
import { robotsettingsApi } from "../../src/views/setting/services/robotsettings.api";
let Layout = class extends Vue {
  constructor() {
    super(...arguments);
    this.robotlist = [];
    this.langList = [
      {
        label: "EN",
        value: "en-US"
      },
      {
        label: "CN",
        value: "zh-CN"
      }
    ];
    this.isShowRenew = false;
    this.circleUrl = "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png";
    this.menu = menu;
    this.routerPath = this.$route.path;
    this.chatbotid = this.$route.query.id;
    this.menuactive = "";
    this.accountInfo = AccountInfoService.getAccountInfo();
    // 当前的语言
    this.nowLang = localStorage.getItem("lang");
    // 是否显示对应模块
    this.showBlock = true;
    //时间
    this.showRenewal = false;
    // 显示弹出提示
    this.showChangeLangTip = false;
    this.Messagelimit = 0;
    //局部刷新
    this.isReloadData = true;
  }
  // 取消提示的显示
  cancelShowTip() {
    this.showChangeLangTip = false;
  }
  // 确认切换中文
  confirmChangeZH() {
    this.showChangeLangTip = false;
    this.nowLang = "zh-CN";
    this.changeLang();
  }
  reloadPart() {
    return __async(this, null, function* () {
      this.isReloadData = false;
      this.$nextTick(() => {
        this.isReloadData = true;
      });
    });
  }
  created() {
    return __async(this, null, function* () {
      if (this.$route.meta.menuactive) {
        this.menuactive = String(this.$route.meta.menuactive);
      }
    });
  }
  mounted() {
    return __async(this, null, function* () {
      this.isShowTime();
      this.$store.commit(
        "userpackage/updateupgradeable",
        (yield robotsettingInfoAPI.getUpgradeable()).datas
      );
      this.robotlist = (yield API.get(`api://base.auth/v1/chatbots`, {
        params: { size: 50, page: 1 }
      })).data.result.datas;
      this.$store.commit(
        "userpackage/update",
        yield robotsettingInfoAPI.getMessagecredits()
      );
      this.Messagelimit = Math.floor(
        this.$store.state.userpackage.nowpackage.used / this.$store.state.userpackage.nowpackage.total * 100
      );
    });
  }
  openaccount() {
    this._account.open();
  }
  goToDetail(id, botname) {
    return __async(this, null, function* () {
      this.chatbotid = id;
      this.$store.commit("chatbot/getnowbotname", botname);
      this.$store.commit(
        "chatbot/getshareId",
        (yield robotsettingsApi.getGeneral(id)).shareId
      );
      this.$router.push({
        path: "/chatbot",
        query: {
          id
        }
      });
    });
  }
  changeLang() {
    return __async(this, null, function* () {
      var _a, _b;
      try {
        if (this.nowLang === "zh-CN") {
          localStorage.setItem("lang", "zh-CN");
          localStorage.removeItem("follow-brower-lang");
          this.$root.$i18n.locale = "zh-CN";
          document.title = this.$t(`${(_a = this.$route.meta) == null ? void 0 : _a.title}`);
          this.accountInfo.language = "zh-CN";
        }
        if (this.nowLang === "en-US") {
          localStorage.setItem("lang", "en-US");
          localStorage.removeItem("follow-brower-lang");
          this.$root.$i18n.locale = "en-US";
          document.title = this.$t(`${(_b = this.$route.meta) == null ? void 0 : _b.title}`);
          this.accountInfo.language = "en-US";
        }
        this.showBlock = false;
        setTimeout(() => {
          this.showBlock = true;
        }, 10);
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  //登出
  logOut() {
    this.$confirm(
      this.$t("auth.log_out.message"),
      this.$t("components.message_box.title"),
      {
        confirmButtonText: this.$t("components.message_box.confirm"),
        cancelButtonText: this.$t("components.message_box.cancel"),
        roundButton: true
      }
    ).then(() => __async(this, null, function* () {
      yield authAPI.logOut();
      localStorage.removeItem("token");
      yield this.$router.push("/auth");
    })).catch(() => {
    });
  }
  isShowTime() {
    return __async(this, null, function* () {
      var currentTimestamp = Math.floor(Date.now() / 1e3);
      let endTime = (yield accountBindAPI.getCombos())[0].endTime;
      var remainingTime = endTime - currentTimestamp;
      var sevenHoursInSeconds = 7 * 60 * 60;
      this.isShowRenew = remainingTime < sevenHoursInSeconds;
    });
  }
  goUpdateserve() {
    this.$router.push("/updateservice");
  }
  goBack() {
    this.$router.push("/");
  }
  goFacebook() {
    window.open("https://www.facebook.com/profile.php?id=100090412004137");
  }
  goTelegram() {
    window.open("https://t.me/chatimport");
  }
  goTwitter() {
    window.open("https://twitter.com/Chatimport");
  }
};
__decorateClass([
  Inject()
], Layout.prototype, "reload", 2);
__decorateClass([
  Ref("account")
], Layout.prototype, "_account", 2);
__decorateClass([
  Debounce(400)
], Layout.prototype, "changeLang", 1);
Layout = __decorateClass([
  Component({
    components: {
      Account
    }
  })
], Layout);
export {
  Layout as default
};
