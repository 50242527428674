"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
export class costdialog {
}
let CostDialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.dialogobjtitle = "";
    // 是否显示弹窗
    this.dialogVisible = false;
    this.price = {
      originalPrice: Number,
      totalPrice: Number
    };
    this.Paymentresult = {};
    this.payorder = {
      paymentKey: "PAYPAL_VALIDATE",
      products: [
        {
          code: this.dialogobj.code,
          quantity: this.dialogobj.quantity
        }
      ]
    };
    this.paymentMethods = [];
  }
  onPersonChanged1(val, oldVal) {
    this.dialogobj = val;
  }
  mounted() {
    return __async(this, null, function* () {
      this.paymentMethods = (yield API.get(`api://base.auth/v1/payment-keys`)).data.datas;
    });
  }
  // 验证支付方式是否可以显示
  checkPaymentMethodShow(method) {
    if (this.paymentMethods.includes(method)) {
      return true;
    } else {
      return false;
    }
  }
  pay() {
    return __async(this, null, function* () {
      this.payorder.products = [
        {
          code: this.dialogobj.code,
          quantity: this.dialogobj.quantity
        }
      ], yield API.post(`api://base.auth/v1/pay-order`, this.payorder).then((data) => {
        this.close();
        if (this.payorder.paymentKey === "PAYPAL_VALIDATE") {
          this.$alert(
            "The link has been sent to your email address. Please go to your email address to complete the payment.",
            "Tip",
            {
              confirmButtonText: "OK"
            }
          );
        } else {
          window.open(data.data.url, "_blank");
        }
      }).catch((err) => {
        this.close();
        throwErrorApi.throwError(err);
      });
    });
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.price = (yield API.post(`api://base.auth/v1/compute`, {
        products: [
          {
            code: this.dialogobj.code,
            quantity: this.dialogobj.quantity
          }
        ]
      })).data;
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
};
__decorateClass([
  Prop(Object)
], CostDialog.prototype, "dialogobj", 2);
__decorateClass([
  Watch("dialogobj", { immediate: true, deep: true })
], CostDialog.prototype, "onPersonChanged1", 1);
CostDialog = __decorateClass([
  Component({
    components: {}
  })
], CostDialog);
export {
  CostDialog as default
};
