"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __pow = Math.pow;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import * as echarts from "echarts";
import { Component, Vue, Watch } from "vue-property-decorator";
import { analyticsapi } from "./services/analytics.api";
import Layout from "@/components/Layout.vue";
import i18n from "@/lang";
export class FormController {
}
let Analytics = class extends Vue {
  constructor() {
    super(...arguments);
    this.number = "Past seven days";
    this.numberMessage = "Past seven days";
    this.dataItems = [
      { label: this.$t("analytics.loads_number"), value: 0 },
      {
        label: this.$t("analytics.clicks_number"),
        value: 0
      },
      { label: this.$t("analytics.tab"), value: 0 },
      { label: this.$t("analytics.leads"), value: 0 }
    ];
    this.messageNum = 0;
    this.isSelected = 0;
    this.options = [
      {
        value: "TODAY",
        label: "today",
        disabled: true
      },
      {
        value: "Yesterday",
        label: "yesterday",
        disabled: true
      },
      {
        value: "Past seven days",
        label: "past seven days"
      },
      {
        value: "Past Twenty-eight days",
        label: "Past Twenty-eight days"
      },
      {
        value: "Past ninety days",
        label: "Past ninety days",
        disabled: true
      },
      {
        value: "More preset ranges",
        label: "More preset ranges",
        disabled: true
      },
      {
        value: "Custom",
        label: "Custom",
        disabled: true
      }
    ];
    this.numberOfLoads = [];
    this.numberOfClicks = [];
    this.interactiveUsers = [];
    this.numberOfLeads = [];
    this.numberOfUsedMessages = [];
    this.EChartOne = null;
    this.configOne = {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross" }
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
        axisLabel: {
          showMaxLabel: true
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 1,
            type: "dashed"
          }
        }
      },
      yAxis: {
        min: (value) => {
          let num = __pow(100, value.min.toString().length - 2);
          return Math.floor(value.min / num) * num;
        },
        max: (value) => {
          let num = __pow(100, value.max.toString().length - 2);
          return Math.ceil(value.max / num) * num;
        },
        scale: true,
        type: "value",
        splitLine: {
          type: "dashed",
          //网格线
          lineStyle: {
            width: 1
          },
          show: true
        }
      },
      //自适应
      grid: {
        height: 150
      },
      series: [
        {
          type: "line",
          smooth: true,
          areaStyle: {},
          data: []
        }
      ]
    };
    this.EChartTwo = null;
    this.configTwo = {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross" }
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
        axisLabel: {
          showMaxLabel: true
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 1,
            type: "dashed"
          }
        }
      },
      yAxis: {
        min: (value) => {
          let num = __pow(100, value.min.toString().length - 2);
          return Math.floor(value.min / num) * num;
        },
        max: (value) => {
          let num = __pow(100, value.max.toString().length - 2);
          return Math.ceil(value.max / num) * num;
        },
        scale: true,
        type: "value",
        splitLine: {
          //网格线
          lineStyle: {
            type: "dashed"
          }
        }
      },
      grid: {
        height: 150
      },
      series: [
        {
          type: "line",
          smooth: true,
          areaStyle: {},
          data: []
        }
      ]
    };
    this.mapdata = [];
    this.mapOption = {
      series: [
        {
          type: "map",
          name: "country",
          label: {
            show: false,
            position: "top"
          },
          mapType: "world",
          roam: false,
          // 开启拖动
          zoom: 1.25,
          center: [10, 10],
          showLegendSymbol: true,
          itemStyle: {
            emphasis: {
              areaColor: "#AFC5FA"
            }
          },
          data: []
        }
      ],
      tooltip: {
        show: true,
        trigger: "item",
        axisPointer: {
          type: "line"
        },
        textStyle: {
          fontSize: 14
        },
        borderWidth: 0,
        // 显示国家
        formatter: function(params) {
          const value = params.value;
          return `${i18n.t("analytics.country")}: <b>
        ${params.name}</b>
      <br />${i18n.t("analytics.value")}:${value ? "<b>" + value + "</b>" : "---"}`;
        }
      },
      visualMap: {
        min: 0,
        max: 1e3,
        calculable: false,
        // 静态
        orient: "horizontal",
        left: -5,
        bottom: "12%",
        inRange: {
          color: ["#F1F3F6", "#4368EE"]
          // 颜色范围
        },
        text: ["1000", "0"],
        // 最大值和最小值
        itemWidth: 15,
        // 颜色块的宽度
        itemHeight: 120
      }
    };
  }
  nowlongChanged(val, oldVal) {
    return __async(this, null, function* () {
      this.dataItems[0].label = this.$t("analytics.loads_number"), this.dataItems[1].label = this.$t("analytics.clicks_number"), this.dataItems[2].label = this.$t("analytics.tab"), this.dataItems[3].label = this.$t("analytics.leads");
    });
  }
  mounted() {
    this.getData(1);
    this.getData(2);
    this.getMapData();
  }
  getMapData() {
    return __async(this, null, function* () {
      let now = new Date();
      let nowtimestamp = now.getTime();
      let dayTimestamp = 24 * 60 * 60;
      let startTime = 0;
      startTime = nowtimestamp / 1e3 - 27 * dayTimestamp;
      this.mapdata = yield analyticsapi.getCountrychats(
        this.$route.query.id,
        {
          start_time: Math.round(startTime),
          end_time: Math.round(nowtimestamp / 1e3)
        }
      );
      let mapDataOption = {
        tooltip: {},
        grid: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10
        },
        xAxis: {
          show: false
        },
        yAxis: {
          type: "category",
          inverse: true,
          max: 9,
          data: this.mapdata.map((item) => {
            return item.countryName;
          }),
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            margin: 10,
            color: "#000",
            fontSize: 16,
            verticalAlign: "bottom",
            align: "top",
            padding: [0, 0, 8, 10]
          }
        },
        series: [
          {
            // current data
            type: "bar",
            color: "#385fec",
            realtimeSort: true,
            // barGap: 5,
            // barCategoryGap: 3,
            barWidth: 5,
            // barMaxWidth: 5,
            showBackground: true,
            data: this.mapdata.map((item) => {
              return item.numberOfChats;
            }),
            label: {
              show: true,
              align: "right",
              color: "#000",
              fontSize: 16,
              position: [300, -18]
            }
          }
        ]
      };
      const chart_container = echarts.init(
        document.getElementById("container"),
        "white",
        { renderer: "canvas" }
      );
      chart_container.resize({
        width: 580,
        height: 460
      });
      this.mapOption.series[0].data = this.mapdata.map((item) => {
        return { name: item.countryName, value: item.numberOfChats };
      });
      chart_container.setOption(this.mapOption);
      var chartDom = document.getElementById("baroption");
      var myChart = echarts.init(chartDom);
      myChart.setOption(mapDataOption);
    });
  }
  handleNumberValue(index) {
    if (this.isSelected === index)
      return;
    this.isSelected = index;
    let array = [
      this.numberOfLoads,
      this.numberOfClicks,
      this.interactiveUsers,
      this.numberOfLeads
    ];
    this.configOne.series[0].data = array[index];
    this.EChartOne.setOption(this.configOne);
  }
  getRenderer() {
    this.EChartOne = this.$echarts.init(document.getElementById("EChart"));
    this.configOne.series[0].data = this.numberOfLoads;
    this.EChartOne.setOption(this.configOne);
    this.$nextTick(() => {
      window.onresize = () => {
        this.EChartOne.resize();
      };
    });
  }
  getRenderertwo() {
    this.configTwo.series[0].data = this.numberOfUsedMessages;
    this.EChartTwo = this.$echarts.init(document.getElementById("EChart2"));
    this.EChartTwo.setOption(this.configTwo);
    this.$nextTick(() => {
      window.onresize = () => {
        this.EChartTwo.resize();
      };
    });
  }
  getData(e) {
    return __async(this, null, function* () {
      this.numberOfLoads = [];
      this.numberOfClicks = [];
      this.interactiveUsers = [];
      this.numberOfLeads = [];
      this.numberOfUsedMessages = [];
      let now = new Date();
      let nowtimestamp = now.getTime();
      let dayTimestamp = 24 * 60 * 60;
      let startTime = 0;
      if (e === 1 && this.number === "Past seven days" || e === 2 && this.numberMessage === "Past seven days") {
        startTime = nowtimestamp / 1e3 - 6 * dayTimestamp;
      } else if (e === 1 && this.number === "Past Twenty-eight days" || e === 2 && this.numberMessage === "Past Twenty-eight days") {
        startTime = nowtimestamp / 1e3 - 27 * dayTimestamp;
      }
      let resdata = yield analyticsapi.getAnalytics(
        this.$route.query.id,
        {
          date_interval: "DAY",
          start_time: Math.round(startTime),
          end_time: Math.round(nowtimestamp / 1e3)
        }
      );
      if (e === 1) {
        resdata.analytics.forEach((item) => {
          this.numberOfLoads.unshift([item.intervalDate, item.numberOfLoads]);
          this.numberOfClicks.unshift([item.intervalDate, item.numberOfClicks]);
          this.interactiveUsers.unshift([
            item.intervalDate,
            item.interactiveUsers
          ]);
          this.numberOfLeads.unshift([item.intervalDate, item.numberOfLeads]);
        });
        this.dataItems[0].value = this.numberOfLoads.reduce(
          (a, b) => Number(a) + Number(b[1]),
          0
        );
        this.dataItems[1].value = this.numberOfClicks.reduce(
          (a, b) => Number(a) + Number(b[1]),
          0
        );
        this.dataItems[2].value = this.interactiveUsers.reduce(
          (a, b) => Number(a) + Number(b[1]),
          0
        );
        this.dataItems[3].value = this.numberOfLeads.reduce(
          (a, b) => Number(a) + Number(b[1]),
          0
        );
        this.getRenderer();
      } else if (e === 2) {
        resdata.analytics.forEach((item) => {
          this.numberOfUsedMessages.unshift([
            item.intervalDate,
            item.numberOfUsedMessages
          ]);
        });
        this.messageNum = this.numberOfUsedMessages.reduce(
          (a, b) => Number(a) + Number(b[1]),
          0
        );
        this.getRenderertwo();
      }
    });
  }
};
__decorateClass([
  Watch("$root.$i18n.locale", { deep: true })
], Analytics.prototype, "nowlongChanged", 1);
Analytics = __decorateClass([
  Component({
    components: {
      Layout
    }
  })
], Analytics);
export {
  Analytics as default
};
