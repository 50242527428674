"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import Layout from "@/components/Layout.vue";
import Unanswer from "./dialog/Unanswer.vue";
import Answer from "./dialog/Answer.vue";
import RobotProgress from "../train/views/dialog/RobotProgress.vue";
import { robottrainAPI } from "../train/services/robottrain.api";
import RobotChat from "../home/views/dialog/RobotChat.vue";
let ChatBot = class extends Vue {
  constructor() {
    super(...arguments);
    this.isShow = true;
    this.scriptid = this.$store.state.chatbot.shareId;
    this.iframeurl = `https://chatimport.com/independent?id=${this.scriptid}`;
    this.unanswered = 0;
    //训练机器人状态
    this.isTraining = false;
    //机器人训练进度
    this.trainingQaProgress = 0;
    this.traindialogobj = {
      title: this.$t("train.training"),
      tip: this.$t("train.tip")
    };
  }
  onPersonChanged1(val, oldVal) {
    this.scriptid = this.$store.state.chatbot.shareId;
    this.iframeurl = `https://chatimport.com/independent?id=${this.scriptid}`;
    this.isShow = false;
    this.$nextTick(() => {
      this.isShow = true;
    });
  }
  mounted() {
    return __async(this, null, function* () {
      this.unanswered = (yield API.get(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/unanswered-question-size`
      )).data.size;
      this.unanswered = this.unanswered > 10 ? 10 : this.unanswered;
      if (this.unanswered != 0) {
        this._Unanswer.open();
      }
    });
  }
  trainingQa() {
    return __async(this, null, function* () {
      this.isTraining = true;
      this._robotprogress.open();
      yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train-task`
      );
      let fn = () => __async(this, null, function* () {
        let progress = yield robottrainAPI.getTrainingQa(
          this.$route.query.id
        );
        if (progress.numberOfFailed + progress.numberOfSuccess != progress.numberOfTotal) {
          this.trainingQaProgress = Math.floor(
            (progress.numberOfFailed + progress.numberOfSuccess) / progress.numberOfTotal * 100
          );
          this.$store.commit("progress/update", this.trainingQaProgress);
        } else {
          this.trainingQaProgress = 100;
          this.$store.commit("progress/update", this.trainingQaProgress);
          this.isTraining = false;
          this._robotprogress.close();
        }
        return fn;
      });
      let timer = setInterval(() => {
        setTimeout(() => {
          fn();
        }, 0);
        if (!this.isTraining) {
          clearInterval(timer);
        }
      }, 3e3);
    });
  }
  openanswer() {
    this._Answer.open();
  }
};
__decorateClass([
  Ref("Unanswer")
], ChatBot.prototype, "_Unanswer", 2);
__decorateClass([
  Ref("Answer")
], ChatBot.prototype, "_Answer", 2);
__decorateClass([
  Ref("robotprogress")
], ChatBot.prototype, "_robotprogress", 2);
__decorateClass([
  Watch("$store.state.chatbot.shareId", { immediate: true, deep: true })
], ChatBot.prototype, "onPersonChanged1", 1);
ChatBot = __decorateClass([
  Component({
    components: {
      Layout,
      Unanswer,
      Answer,
      RobotProgress,
      RobotChat
    }
  })
], ChatBot);
export {
  ChatBot as default
};
