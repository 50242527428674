"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import AccountCenter from "@/framework/sdk/account-center-client/utils";
import { AccountAuthentication } from "@/framework/sdk/account-center-client/authentications";
import { AccountAuthModel } from "@/framework/sdk/account-center-client/models/form.model";
import API from "@ym-framework/api-utils";
class AuthApi {
  /**
   * 注册
   * @param vo
   */
  register(vo) {
    return __async(this, null, function* () {
      return yield API.post(
        `api://base/v1/register`,
        vo
      );
    });
  }
  /**
   * 找回密码
   * @param vo
   */
  resetPassword(vo) {
    return __async(this, null, function* () {
      return yield API.post(
        `api://base/v1/retrieve-password`,
        vo
      );
    });
  }
  /**
   * 登录
   * @param vo
   */
  login(vo) {
    return __async(this, null, function* () {
      return yield AccountCenter.Auth(
        new AccountAuthentication(
          new AccountAuthModel(vo.account, vo.password)
        )
      );
    });
  }
  /**
   * 退出登录
   */
  logOut() {
    return __async(this, null, function* () {
      return yield API.delete(`api://base.auth/v1/logout`);
    });
  }
}
export const authAPI = new AuthApi();
