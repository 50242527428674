"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class RobotSettingsApi {
  //获取General配置
  getGeneral(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/setting/general`
      );
      return response.data;
    });
  }
  //获取Interface配置
  getInterface(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/setting/interface`
      );
      return response.data;
    });
  }
  //获取DataCollection配置
  getDataCollection(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/setting/data-collection`
      );
      return response.data;
    });
  }
  //获取Notifications配置
  getNotifications(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/setting/notifications`
      );
      return response.data;
    });
  }
  //reset基础提示和未回答
  reset(id, params) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/setting/reset`,
        {
          params
        }
      );
      return response.data;
    });
  }
}
export const robotsettingsApi = new RobotSettingsApi();
