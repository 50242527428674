"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import { Message } from "element-ui";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import { robotsettingsApi } from "../../services/robotsettings.api";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.enableDataCollection = false;
    this.title = "Let me know how to contact you";
    this.enableVisitorNameCollection = false;
    this.visitorNameInputKey = "Name";
    this.visitorNamePrompt = "Enter your Name";
    this.visitorNameInputRequired = false;
    this.enableVisitorEmailCollection = false;
    this.visitorEmailInputKey = "Email";
    this.visitorEmailPrompt = "Enter your Email";
    this.visitorEmailInputRequired = false;
    this.enableVisitorPhoneCollection = false;
    this.visitorPhoneInputKey = "Phone";
    this.visitorPhonePrompt = "Enter your Phone";
    this.visitorPhoneInputRequired = false;
    this.enableVisitorTelegramCollection = false;
    this.visitorTelegramInputKey = "Telegram";
    this.visitorTelegramPrompt = "Enter your Telegram";
    this.visitorTelegramInputRequired = false;
    this.enableVisitorWhatsAppCollection = false;
    this.visitorWhatsAppInputKey = "WhatsApp";
    this.visitorWhatsAppPrompt = "Enter your WhatsApp";
    this.visitorWhatsAppInputRequired = false;
  }
}
let DateForm = class extends Vue {
  constructor() {
    super(...arguments);
    this.robotsettingdata = {};
    // 创建机器人设置表单controller
    this.controller = new FormController();
  }
  mounted() {
    return __async(this, null, function* () {
      let obj = yield robotsettingsApi.getDataCollection(
        this.$route.query.id
      );
      Object.keys(obj).forEach((key) => {
        if (obj[key].length !== 0) {
          this.controller[key] = obj[key];
        }
      });
    });
  }
  rename() {
    this.controller.visitorNameInputKey = "Name";
    this.controller.visitorNamePrompt = "Enter your Name";
  }
  reemail() {
    this.controller.visitorEmailInputKey = "Email";
    this.controller.visitorEmailPrompt = "Enter your Email";
  }
  rephone() {
    this.controller.visitorPhoneInputKey = "Phone Number";
    this.controller.visitorPhonePrompt = "Enter your Phone Number";
  }
  reTelegram() {
    this.controller.visitorTelegramInputKey = "Telegram";
    this.controller.visitorTelegramPrompt = "Enter your Telegram";
  }
  submit() {
    return __async(this, null, function* () {
      try {
        this.controller.status = UiFormStatus.LOADING;
        yield API.put(
          `api://base.auth/v1/chatbots/${this.$route.query.id}/setting/data-collection`,
          this.controller
        ).then(() => {
          Message.success(this.$t("settings.generl.success"));
        }).catch((err) => {
          throwErrorApi.throwError(err);
        });
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
};
__decorateClass([
  Ref("Form")
], DateForm.prototype, "_form", 2);
DateForm = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], DateForm);
export {
  DateForm as default
};
