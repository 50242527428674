"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, PropSync, Prop, Vue } from "vue-property-decorator";
import { InputVerifyCodeService } from "@/shared/components/input-verify-code/services/input-verify-code.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { Debounce } from "vue-debounce-decorator";
let InputVerifyCode = class extends Vue {
  constructor() {
    super(...arguments);
    this.inputVerifyCodeService = new InputVerifyCodeService();
    this.flag = true;
  }
  postVerifyCode() {
    return __async(this, null, function* () {
      if (!this.flag) {
        return;
      }
      this.flag = false;
      const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!this.email || this.email.length < 6 || this.email.length > 100 || !reg.test(this.email)) {
        this.$emit("validateEmail");
      } else {
        try {
          yield this.inputVerifyCodeService.sendCode({
            account: this.email,
            messageType: this.type
          });
        } catch (error) {
          this.inputVerifyCodeService = new InputVerifyCodeService();
          throwErrorApi.throwError(error);
        }
      }
      setTimeout(() => {
        this.flag = true;
      }, 3e3);
    });
  }
};
__decorateClass([
  PropSync(
    "verifyCode",
    { type: String, default: "" }
  )
], InputVerifyCode.prototype, "_verifyCode", 2);
__decorateClass([
  Prop()
], InputVerifyCode.prototype, "email", 2);
__decorateClass([
  Prop()
], InputVerifyCode.prototype, "type", 2);
__decorateClass([
  Debounce(400)
], InputVerifyCode.prototype, "postVerifyCode", 1);
InputVerifyCode = __decorateClass([
  Component({
    components: {}
  })
], InputVerifyCode);
export {
  InputVerifyCode as default
};
