"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
import { CoreAuthentication } from "../core";
export class AccountAuthentication extends CoreAuthentication {
  auth() {
    return __async(this, null, function* () {
      const _response = yield API.post("api://account-center/v1/login", this._post);
      return _response.data;
    });
  }
}
export class PhoneAuthentication extends CoreAuthentication {
  auth() {
    return __async(this, null, function* () {
      const _response = yield API.post("api://account-center/v1/auth/phone", this._post);
      return _response.data;
    });
  }
}
export class GuestAuthentication extends CoreAuthentication {
  auth() {
    return __async(this, null, function* () {
      const _response = yield API.post("api://account-center/v1/auth/guests");
      return _response.data;
    });
  }
}
