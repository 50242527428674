"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import { Message } from "element-ui";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import API from "@ym-framework/api-utils";
import UiSubmit from "@/components/form/UiSubmit.vue";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { robotsettingsApi } from "../../services/robotsettings.api";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.leads = {
      enable: false,
      email: "",
      cron: {
        frequencyType: "WEEKLY",
        dayOfWeek: 1,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    };
    this.conversations = {
      enable: false,
      email: "",
      cron: {
        frequencyType: "DAILY",
        dayOfWeek: 1,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    };
  }
}
let Notifications = class extends Vue {
  constructor() {
    super(...arguments);
    this.controller = new FormController();
    this.accountInfo = AccountInfoService.getAccountInfo();
    this.option = [
      {
        value: 1,
        label: "Monday"
      },
      {
        value: 2,
        label: "Tuesday"
      },
      {
        value: 3,
        label: "Wednesday"
      },
      {
        value: 4,
        label: "Thursday"
      },
      {
        value: 5,
        label: "Friday"
      },
      {
        value: 6,
        label: "Saturday"
      },
      {
        value: 7,
        label: "Sunday"
      }
    ];
  }
  isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com)$/i;
    return emailPattern.test(email);
  }
  mounted() {
    return __async(this, null, function* () {
      let obj = yield robotsettingsApi.getNotifications(
        this.$route.query.id
      );
      Object.keys(obj).forEach((key) => {
        if (obj[key].length !== 0) {
          this.controller[key] = obj[key];
        }
      });
      if (this.controller.leads.email === null) {
        this.controller.leads.email = this.accountInfo.account;
      }
      if (this.controller.conversations.email === null) {
        this.controller.conversations.email = this.accountInfo.account;
      }
    });
  }
  submit() {
    return __async(this, null, function* () {
      if (!this.controller.conversations.email && !this.controller.leads.email) {
        this.$message.error(
          this.$t("settings.notifications.emailerror")
        );
        return;
      } else if (!this.controller.conversations.email) {
        this.$message.error(
          this.$t("settings.notifications.conversationsemail")
        );
        return;
      } else if (!this.controller.leads.email) {
        this.$message.error(
          this.$t("settings.notifications.leadsemail")
        );
        return;
      } else if (!this.isValidEmail(this.controller.leads.email) || !this.isValidEmail(this.controller.conversations.email)) {
        this.$message.error(this.$t("settings.notifications.tip"));
        return;
      }
      try {
        this.controller.status = UiFormStatus.LOADING;
        yield API.put(
          `api://base.auth/v1/chatbots/${this.$route.query.id}/setting/notifications`,
          this.controller
        ).then(() => __async(this, null, function* () {
          Message.success(this.$t("settings.generl.success"));
        })).catch((err) => {
          throwErrorApi.throwError(err);
        });
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
};
Notifications = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], Notifications);
export {
  Notifications as default
};
