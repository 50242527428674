"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Annotation, CoreModel } from "@ym-framework/model";
import { DataSetModel } from "./dataset.model";
export let ErrorsModel = class extends CoreModel {
  constructor(errors) {
    super();
    this.errors = new DataSetModel();
    if (errors) {
      for (const _error of errors) {
        this.errors.datas.push(_error);
      }
    }
  }
};
ErrorsModel.model = "errors";
ErrorsModel = __decorateClass([
  Annotation.ModelMeta
], ErrorsModel);
export let ErrorModel = class extends CoreModel {
  constructor(code, message) {
    super();
    this.code = "";
    this.message = "";
    if (code) {
      this.code = code;
    }
    if (message) {
      this.message = message;
    }
  }
};
ErrorModel.model = "error";
ErrorModel = __decorateClass([
  Annotation.ModelMeta
], ErrorModel);
