"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiForm, {
  UiFormController
} from "@/components/form/UiForm.vue";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import API from "@ym-framework/api-utils";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.formarray = [
      {
        isShow: true,
        fileId: "",
        question: "",
        answer: "",
        imgList: []
      }
    ];
  }
}
let RobotAddQa = class extends Vue {
  constructor() {
    super(...arguments);
    // 创建表单controller
    this.controller = new FormController();
    this.accountInfo = AccountInfoService.getAccountInfo();
    // 是否显示弹窗
    this.dialogVisible = false;
    this.imageUrl = "";
    this.fileFormData = new FormData();
    this.dialogImageUrl = "";
    this.isLoading = false;
    this.imgdialogVisible = false;
  }
  handleRemove(file, item) {
    let index = this.controller.formarray.indexOf(item);
    this.fileFormData.delete(this.controller.formarray[index].fileId);
    this.controller.formarray[index].imgList = [];
    this.controller.formarray[index].fileId = "";
  }
  handlePictureCardPreview(file) {
    this.dialogImageUrl = file.url;
    this.imgdialogVisible = true;
  }
  changefile(file, fileList, item) {
    const isJPGPNGGIF = /\.(jpg|png|gif)$/i.test(file.name);
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPGPNGGIF) {
      fileList = fileList.splice(0, 1);
      this.$message.error(this.$t("train.images"));
      return false;
    }
    if (!isLt2M) {
      fileList = fileList.splice(0, 1);
      this.$message.error(this.$t("train.image2"));
      return false;
    }
    let index = this.controller.formarray.indexOf(item);
    this.controller.formarray[index].imgList = [
      {
        name: file.name,
        url: file.url
      }
    ];
    this.controller.formarray[index].fileId = file.raw.uid;
    this.fileFormData.append(file.raw.uid, file.raw);
  }
  submit() {
    return __async(this, null, function* () {
      this.isLoading = false;
      let submitArr = [];
      let hasEmptyFields = false;
      this.controller.formarray.forEach((i) => {
        if (i.isShow) {
          if (!i.question && !i.answer) {
            hasEmptyFields = true;
            this.$message.error(this.$t("train.error"));
          } else if (!i.question || !i.answer) {
            hasEmptyFields = true;
            this.$message.error(this.$t("train.error2"));
          }
          submitArr.push({
            fileId: i.fileId,
            question: i.question,
            answer: i.answer
          });
        }
      });
      if (hasEmptyFields) {
        return;
      }
      this.close();
      this.fileFormData.append(
        "chatbot_qa_corpora",
        new Blob([JSON.stringify(submitArr)], {
          type: "application/json"
        })
      );
      try {
        yield API.post(
          `api://base.auth/v2/chatbots/${this.$route.query.id}/train/qa`,
          this.fileFormData
        );
        this.$emit("reloadPart");
      } finally {
        this.isLoading = false;
        this.fileFormData = new FormData();
      }
    });
  }
  //添加一条
  addform() {
    this.controller.formarray.push({
      isShow: true,
      fileId: "",
      question: "",
      answer: "",
      imgList: []
    });
  }
  //删除一个
  remove(item) {
    let index = this.controller.formarray.indexOf(item);
    this.fileFormData.delete(this.controller.formarray[index].fileId);
    if (index !== -1) {
      this.controller.formarray[index].isShow = false;
    }
  }
  //全部删除
  removeform() {
    this.controller.formarray = [];
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.controller = new FormController();
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
};
__decorateClass([
  Ref("Form")
], RobotAddQa.prototype, "_form", 2);
RobotAddQa = __decorateClass([
  Component({
    components: { UiForm }
  })
], RobotAddQa);
export {
  RobotAddQa as default
};
