"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import ChatInterface from "./components/ChatInterface.vue";
import General from "./components/General.vue";
import DateForm from "./components/DateForm.vue";
import Notifications from "./components/Notifications.vue";
let Setting = class extends Vue {
};
Setting = __decorateClass([
  Component({
    components: {
      Layout,
      ChatInterface,
      General,
      DateForm,
      Notifications
    }
  })
], Setting);
export {
  Setting as default
};
