"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class RobotShareApi {
  //获取share配置
  getShare(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/share`
      );
      return response.data;
    });
  }
  //获取share机器人配置
  getShareSettings(share_id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base/public/v1/chatbots/${share_id}/aggregation-setting`
      );
      return response.data;
    });
  }
  // 获取share机器人训练历史聊天记录
  getShareChattingRecords(share_id, visitor_id, message_id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base/public/v1/chatbots/${share_id}/visitor/${visitor_id}/chat
			-history`,
        {
          params: {
            message_id
          }
        }
      );
      return response.data;
    });
  }
  //获取时区
  getTimezone(id, params) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/share/advanced-setting-timezone/identifier`,
        {
          params
        }
      );
      return response.data;
    });
  }
  //获取时区列表
  getTimezoneList(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/share/advanced-setting-timezone`
      );
      return response.data;
    });
  }
}
export const robotshareApi = new RobotShareApi();
