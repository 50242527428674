"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import {
  Component,
  Ref,
  Vue,
  Watch
} from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import API from "@ym-framework/api-utils";
import RobotQA from "./components/RobotQA.vue";
import RobotFiles from "./components/RobotFiles.vue";
import RobotText from "./components/RobotText.vue";
import RobotWebsite from "./components/RobotWebsite.vue";
import RobotProgress from "./dialog/RobotProgress.vue";
import { robottrainAPI } from "../services/robottrain.api";
import i18n from "@/lang";
import { Message } from "element-ui";
let Train = class extends Vue {
  constructor() {
    super(...arguments);
    //训练机器人状态
    this.isTraining = false;
    //机器人训练进度
    this.trainingQaProgress = 0;
    //机器人使用文件统计
    this.Usedstatistics = {};
    this.lastTrain = 0;
    this.traindialogobj = {
      title: i18n.t("train.training"),
      tip: i18n.t("train.tip")
    };
  }
  nowlongChanged(val, oldVal) {
    return __async(this, null, function* () {
      this.traindialogobj = {
        title: this.$t("train.training"),
        tip: this.$t("train.tip")
      };
    });
  }
  mounted() {
    return __async(this, null, function* () {
      this.Usedstatistics = yield robottrainAPI.getUsed(
        this.$route.query.id
      );
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train-task/records`
      );
      const tainhistory = response.data;
      if (tainhistory.length > 0) {
        this.lastTrain = tainhistory[0].endTime;
      }
    });
  }
  //训练智能客服
  trainingQa() {
    return __async(this, null, function* () {
      this.isTraining = true;
      this._robotprogress.open();
      yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train-task`
      );
      let fn = () => __async(this, null, function* () {
        let progress = yield robottrainAPI.getTrainingQa(
          this.$route.query.id
        );
        if (progress.numberOfFailed + progress.numberOfSuccess != progress.numberOfTotal) {
          this.trainingQaProgress = Math.floor(
            (progress.numberOfFailed + progress.numberOfSuccess) / progress.numberOfTotal * 100
          );
          this.$store.commit("progress/update", this.trainingQaProgress);
        } else {
          this.trainingQaProgress = 100;
          this.$store.commit("progress/update", this.trainingQaProgress);
          this.isTraining = false;
          this.numberOfFailed = progress.numberOfFailed;
          this._robotprogress.close();
        }
        return fn;
      });
      let timer = setInterval(() => __async(this, null, function* () {
        setTimeout(() => {
          fn();
        }, 0);
        if (!this.isTraining) {
          clearInterval(timer);
          const response = yield API.get(
            `api://base.auth/v1/chatbots/${this.$route.query.id}/train-task/records`
          );
          const tainhistory = response.data;
          if (tainhistory.length > 0) {
            this.lastTrain = tainhistory[0].endTime;
          }
          if (this.numberOfFailed) {
            Message.error(this.$t("train.failed"));
          } else {
            Message.success(this.$t("train.successful"));
          }
        }
      }), 3e3);
    });
  }
  goback() {
    this.$router.go(-1);
  }
};
__decorateClass([
  Ref("robotprogress")
], Train.prototype, "_robotprogress", 2);
__decorateClass([
  Watch("$root.$i18n.locale", { deep: true })
], Train.prototype, "nowlongChanged", 1);
Train = __decorateClass([
  Component({
    components: {
      Layout,
      RobotQA,
      RobotFiles,
      RobotWebsite,
      RobotText,
      RobotProgress
    }
  })
], Train);
export {
  Train as default
};
