"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiForm, { UiFormController } from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import InputVerifyCode from "@/shared/components/input-verify-code/InputVerifyCode.vue";
import { ForgetPasswordService } from "@/views/auth/services/forget-password.service";
import i18n from "@/lang";
import { Debounce } from "vue-debounce-decorator";
export class ForgetPasswordController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.password = "";
    this.verifyCode = "";
    this.repeatPassword = "";
  }
}
let ForgetPassword = class extends Vue {
  constructor() {
    super(...arguments);
    // 忘记密码Controller
    this.controller = new ForgetPasswordController();
    // 忘记密码Service
    this.forgetPasswordService = new ForgetPasswordService();
    // 邮箱账号校验规则
    this.forgetAccountRules = [
      {
        required: true,
        message: i18n.t("auth.info.email_required"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 100,
        message: i18n.t("auth.info.email_length"),
        trigger: "blur"
      },
      {
        type: "email",
        message: i18n.t("auth.info.email_legality"),
        trigger: "blur"
      }
    ];
    // 忘记密码重置密码校验规则
    this.forgetPasswordRules = [
      {
        required: true,
        message: i18n.t("auth.info.password_required"),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)[^\u4e00-\u9fa5 ]+$/,
        message: i18n.t("auth.info.password_format"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 24,
        message: i18n.t("auth.info.password_length"),
        trigger: "blur"
      }
    ];
    // 验证码校验规则
    this.forgetVerifyRules = [
      {
        required: true,
        message: i18n.t("components.verify_code.placeholder"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 6,
        message: i18n.t("components.verify_code.format"),
        trigger: "blur"
      }
    ];
    // 二次校验密码校验规则
    this.repeatPasswordRule = [
      {
        required: true,
        message: i18n.t("auth.info.confirm_password_required"),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)[^\u4e00-\u9fa5 ]+$/,
        message: i18n.t("auth.info.password_format"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 24,
        message: i18n.t("auth.info.password_length"),
        trigger: "blur"
      },
      {
        validator: this.checkRepeatPassword,
        trigger: "blur"
      }
    ];
  }
  // 校验邮箱格式
  validateEmail() {
    this._form._form.validateField("account", (errMsg) => {
      if (errMsg) {
        throw Error();
      }
    });
  }
  // 检测密码是否一致
  checkRepeatPassword(rule, value, callback) {
    return this.forgetPasswordService.checkRepeatPassword(value, this.controller.password, callback);
  }
  submit() {
    this.$emit("resetPassword", this.controller);
    this.controller = new ForgetPasswordController();
  }
  // 前往注册页
  goToRegister() {
    this._form._form.resetFields();
    this.$emit("goToRegister");
  }
  // 前往登录页
  goToLogin() {
    this._form._form.resetFields();
    this.$emit("goToLogin");
  }
};
__decorateClass([
  Ref("forgetPasswordForm")
], ForgetPassword.prototype, "_form", 2);
__decorateClass([
  Debounce(400)
], ForgetPassword.prototype, "submit", 1);
ForgetPassword = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      InputPassword,
      InputVerifyCode
    }
  })
], ForgetPassword);
export {
  ForgetPassword as default
};
