"use strict";
import { Message } from "element-ui";
class ThrowErrorApi {
  /**
   * 打印异常
   * @param error
   */
  printError(error) {
    const _error = error;
    console.dir(_error.response);
  }
  /**
   * 提醒异常
   * @param error
   */
  throwError(error) {
    var _a, _b;
    const _error = error;
    console.dir(_error.response);
    if (_error.isAxiosError) {
      Message.error((_b = (_a = _error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.errors.datas[0].message);
    }
  }
  /**
   * token过期
   * @param error
   * @param next
   */
  tokenError(error, next) {
    var _a, _b;
    const _error = error;
    console.dir(_error.response);
    if (_error.isAxiosError && ((_b = (_a = _error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.errors.datas[0].code) === "auth.error.code.token.expired") {
      next("/auth");
    }
  }
}
export const throwErrorApi = new ThrowErrorApi();
