"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Ref, Vue } from "vue-property-decorator";
import costdialog from "../../cost/CostDialog.vue";
import i18n from "@/lang";
let UpdateService = class extends Vue {
  constructor() {
    super(...arguments);
    this.Month = [
      {
        name: this.$t("upgrade.info.entry"),
        code: "ENTRY_MONTH_COMBO",
        quantity: 1,
        presentprice: "14",
        originalprice: "19"
      },
      {
        name: this.$t("upgrade.info.premium"),
        code: "GROWTH_MONTH_COMBO",
        quantity: 1,
        presentprice: "72",
        originalprice: "90"
      },
      {
        name: this.$t("upgrade.info.professional"),
        code: "STANDARD_MONTH_COMBO",
        quantity: 1,
        presentprice: "260",
        originalprice: "300"
      },
      {
        name: this.$t("upgrade.info.business"),
        code: "UNLIMITED_MONTH_COMBO",
        quantity: 1,
        presentprice: "550"
      }
    ];
    this.Year = [
      {
        name: this.$t("upgrade.info.entry"),
        code: "ENTRY_YEAR_COMBO",
        quantity: 1,
        presentprice: "190",
        originalprice: "18"
      },
      {
        name: this.$t("upgrade.info.premium"),
        code: "GROWTH_YEAR_COMBO",
        quantity: 1,
        presentprice: "900",
        originalprice: "46"
      },
      {
        name: this.$t("upgrade.info.professional"),
        code: "STANDARD_YEAR_COMBO",
        quantity: 1,
        presentprice: "3000",
        originalprice: "90"
      },
      {
        name: this.$t("upgrade.info.business"),
        code: "UNLIMITED_YEAR_COMBO",
        quantity: 1,
        presentprice: "5500",
        originalprice: "300"
      }
    ];
    this.service = this.Month;
    this.togglename = "Monthly";
    this.dialogobj = {
      title: "",
      Packagename: "",
      code: "",
      quantity: 1
    };
    this.questionlist = [
      {
        question: this.$t("upgrade.pricing.question1"),
        answer: this.$t("upgrade.pricing.answer1")
      },
      {
        question: this.$t("upgrade.pricing.question2"),
        answer: this.$t("upgrade.pricing.answer2")
      },
      {
        question: this.$t("upgrade.pricing.question3"),
        answer: this.$t("upgrade.pricing.answer3")
      },
      {
        question: this.$t("upgrade.pricing.question4"),
        answer: this.$t("upgrade.pricing.answer4")
      },
      {
        question: this.$t("upgrade.pricing.question5"),
        answer: this.$t("upgrade.pricing.answer5")
      },
      {
        question: this.$t("upgrade.pricing.question6"),
        answer: this.$t("upgrade.pricing.answer6")
      },
      {
        question: this.$t("upgrade.pricing.question7"),
        answer: this.$t("upgrade.pricing.answer7")
      }
    ];
  }
  mounted() {
    var _a;
    document.title = i18n.t(`${(_a = this.$route.meta) == null ? void 0 : _a.title}`);
  }
  toggle(tabname) {
    this.togglename = tabname;
    if (tabname == "Monthly") {
      this.service = this.Month;
    } else {
      this.service = this.Year;
    }
  }
  opencostdialog(Packagename, code, quantity) {
    this.dialogobj.Packagename = Packagename;
    this.dialogobj.code = code;
    this.dialogobj.quantity = quantity;
    this._costdialog.open();
  }
  goback() {
    this.$router.go(-1);
  }
};
__decorateClass([
  Ref("costdialog")
], UpdateService.prototype, "_costdialog", 2);
UpdateService = __decorateClass([
  Component({
    components: {
      costdialog
    }
  })
], UpdateService);
export {
  UpdateService as default
};
