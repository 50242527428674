"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import UiForm, {
  UiFormController
} from "@/components/form/UiForm.vue";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import API from "@ym-framework/api-utils";
class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.fileId = "";
    this.question = "";
    this.answer = "";
  }
}
let RobotChangeQa = class extends Vue {
  constructor() {
    super(...arguments);
    // 创建表单controller
    this.controller = new FormController();
    this.accountInfo = AccountInfoService.getAccountInfo();
    // 是否显示弹窗
    this.dialogVisible = false;
    this.imageUrl = "";
    this.fileFormData = new FormData();
    this.fileList = [];
    this.loading = false;
    this.dialogImageUrl = "";
    this.imgdialogVisible = false;
  }
  handleRemove(file) {
    this.fileList = [];
    this.fileFormData = new FormData();
    this.controller.fileId = "";
  }
  handlePictureCardPreview(file) {
    this.dialogImageUrl = file.url;
    this.imgdialogVisible = true;
  }
  changefile(file, fileList) {
    const isJPGPNGGIF = /\.(jpg|png|gif)$/i.test(file.name);
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPGPNGGIF) {
      fileList = fileList.splice(0, 1);
      this.$message.error(this.$t("train.images"));
      return;
    }
    if (!isLt2M) {
      fileList = fileList.splice(0, 1);
      this.$message.error(this.$t("train.image2"));
      return;
    }
    this.fileList = [
      {
        name: file.name,
        url: file.url
      }
    ];
    this.controller.fileId = file.raw.uid;
    this.fileFormData.append(file.raw.uid, file.raw);
  }
  // beforeUpload(file: any) {
  //   const isJPGPNGGIF = /\.(jpg|png|gif)$/i.test(file.name);
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isJPGPNGGIF) {
  //     this.$message.error("只能上传jpg、png或gif格式的图片");
  //   }
  //   if (!isLt2M) {
  //     this.$message.error("图片大小不能超过2MB");
  //   }
  //   return isJPGPNGGIF && isLt2M;
  // }
  submit() {
    return __async(this, null, function* () {
      if (!this.controller.question && !this.controller.answer) {
        this.$message.error(this.$t("train.error"));
        return;
      } else if (!this.controller.question || !this.controller.answer) {
        this.$message.error(this.$t("train.error2"));
        return;
      }
      this.close();
      try {
        this.fileFormData.append(
          "chatbot_qa_corpus",
          new Blob([JSON.stringify(this.controller)], {
            type: "application/json"
          })
        );
        yield API.put(
          `api://base.auth/v2/chatbots/${this.$route.query.id}/train/qa/${this.qaid}`,
          this.fileFormData
        );
        this.$emit("reloadPart");
      } finally {
        this.loading = false;
      }
    });
  }
  // 通过 url 获取到 File
  getFileFromUrl(url, fileName) {
    return __async(this, null, function* () {
      return new Promise((resolve, reject) => {
        let blob = null;
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = () => {
          blob = xhr.response;
          let file = new File([blob], fileName, { type: blob.type });
          resolve(file);
        };
        xhr.onerror = (e) => {
          reject(e);
        };
        xhr.send();
      });
    });
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.$nextTick(() => {
        this.fileFormData = new FormData();
        this.controller.question = this.beforeChangeData.question;
        this.controller.answer = this.beforeChangeData.answer;
        this.qaid = this.beforeChangeData.id;
      });
      if (this.beforeChangeData.files.length > 0) {
        try {
          const fileData = this.beforeChangeData.files[0];
          const file = yield this.getFileFromUrl(
            fileData.fileUrl,
            fileData.fileName
          );
          this.controller.fileId = file.lastModified;
          this.fileFormData = new FormData();
          this.fileFormData.append(file.lastModified, file);
          this.fileList = [
            {
              name: this.beforeChangeData.files[0].fileName,
              url: this.beforeChangeData.files[0].fileUrl
            }
          ];
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.fileList = [];
    this.dialogVisible = false;
  }
};
__decorateClass([
  Prop()
], RobotChangeQa.prototype, "beforeChangeData", 2);
__decorateClass([
  Ref("Form")
], RobotChangeQa.prototype, "_form", 2);
RobotChangeQa = __decorateClass([
  Component({
    components: { UiForm }
  })
], RobotChangeQa);
export {
  RobotChangeQa as default
};
