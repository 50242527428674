"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Prop } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
let RobotExportQa = class extends Vue {
  constructor() {
    super(...arguments);
    // 是否显示弹窗
    this.dialogVisible = false;
    this.delarr = new Array();
  }
  submit() {
    return __async(this, null, function* () {
      let resfile = yield API.post(
        `api://base.auth/v1/chatbots/${this.$route.query.id}/train/qa/export`,
        {
          textIds: this.delarr
        },
        {
          responseType: "blob"
        }
      );
      var debug = resfile;
      if (debug) {
        var elink = document.createElement("a");
        elink.download = "Exportedtable.xlsx";
        elink.style.display = "none";
        var blob = debug.data;
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      } else {
        this.$message.error("Export failure");
      }
      this.close();
    });
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.$nextTick(() => __async(this, null, function* () {
        this.delarr = [];
        this.robotqaSelection.forEach((i) => {
          this.delarr.push(i.id);
        });
      }));
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
};
__decorateClass([
  Prop()
], RobotExportQa.prototype, "robotqaSelection", 2);
RobotExportQa = __decorateClass([
  Component({
    components: {}
  })
], RobotExportQa);
export {
  RobotExportQa as default
};
