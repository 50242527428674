"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class dashboardApi {
  //获取房间列表
  getChatroom(id, params) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/chatroom`,
        {
          params
        }
      );
      return response.data;
    });
  }
  //获取房间列表
  getChatroomHistory(id, message_id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatroom/${id}/history`,
        {
          params: {
            message_id
          }
        }
      );
      return response.data;
    });
  }
  // 获取机器人房间聊天历史记录对应的训练原始语料数据
  getOriginalCorpus(id, message_id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/chat-history/original-corpus`,
        {
          params: {
            message_id
          }
        }
      );
      return response.data;
    });
  }
}
export const dashboardapi = new dashboardApi();
