"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Watch } from "vue-property-decorator";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { accountBindAPI } from "@/shared/api/account-bind/account-bind.api";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import i18n from "@/lang";
export class Packageobj {
  constructor() {
    this.FREE_TRIAL_COMBO = i18n.t("upgrade.info.free");
    this.ENTRY_MONTH_COMBO = i18n.t("upgrade.info.entry");
    this.ENTRY_YEAR_COMBO = i18n.t("upgrade.info.entry");
    this.GROWTH_MONTH_COMBO = i18n.t("upgrade.info.premium");
    this.GROWTH_YEAR_COMBO = i18n.t("upgrade.info.premium");
    this.STANDARD_MONTH_COMBO = i18n.t("upgrade.info.professional");
    this.STANDARD_YEAR_COMBO = i18n.t("upgrade.info.professional");
    this.UNLIMITED_MONTH_COMBO = i18n.t("upgrade.info.business");
    this.UNLIMITED_YEAR_COMBO = i18n.t("upgrade.info.business");
  }
}
let Account = class extends Vue {
  constructor() {
    super(...arguments);
    this.isEditPaypal = false;
    this.accountInfo = AccountInfoService.getAccountInfo();
    this.circleUrl = "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png";
    this.dialogVisible = false;
    this.paypalemail = "";
    this.userPaypal = "";
    this.APIKEY = "";
    this.rights = {
      FREE_TRIAL_COMBO: [
        "30" + this.$t("upgrade.info.message"),
        "1" + this.$t("upgrade.info.chatbot"),
        "400,000 " + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share")
      ],
      ENTRY_MONTH_COMBO: [
        "2000" + this.$t("upgrade.info.message"),
        "2 " + this.$t("upgrade.info.chatbot"),
        "2,000,000" + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share"),
        this.$t("upgrade.info.upload_multiple_files"),
        this.$t("upgrade.info.upload_websites"),
        this.$t("upgrade.info.Collect")
      ],
      ENTRY_YEAR_COMBO: [
        "2000" + this.$t("upgrade.info.message"),
        "2 " + this.$t("upgrade.info.chatbot"),
        "2,000,000" + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share"),
        this.$t("upgrade.info.upload_multiple_files"),
        this.$t("upgrade.info.upload_websites"),
        this.$t("upgrade.info.Collect")
      ],
      GROWTH_MONTH_COMBO: [
        "10000" + this.$t("upgrade.info.message"),
        "5 " + this.$t("upgrade.info.chatbot"),
        "6,000,000 " + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share"),
        this.$t("upgrade.info.upload_multiple_files"),
        this.$t("upgrade.info.upload_websites"),
        this.$t("upgrade.info.Collect"),
        this.$t("upgrade.info.api_access"),
        this.$t("upgrade.info.gpt")
      ],
      GROWTH_YEAR_COMBO: [
        "10000" + this.$t("upgrade.info.message"),
        "5 " + this.$t("upgrade.info.chatbot"),
        "6,000,000 " + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share"),
        this.$t("upgrade.info.upload_multiple_files"),
        this.$t("upgrade.info.upload_websites"),
        this.$t("upgrade.info.Collect"),
        this.$t("upgrade.info.api_access"),
        this.$t("upgrade.info.gpt")
      ],
      STANDARD_MONTH_COMBO: [
        "30000" + this.$t("upgrade.info.message"),
        "11 " + this.$t("upgrade.info.chatbot"),
        "8,000,000 " + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share"),
        this.$t("upgrade.info.upload_multiple_files"),
        this.$t("upgrade.info.upload_websites"),
        this.$t("upgrade.info.Collect"),
        this.$t("upgrade.info.api_access"),
        this.$t("upgrade.info.gpt"),
        this.$t("upgrade.pricing.api"),
        this.$t("upgrade.info.remove")
      ],
      STANDARD_YEAR_COMBO: [
        "30000" + this.$t("upgrade.info.message"),
        "11 " + this.$t("upgrade.info.chatbot"),
        "8,000,000 " + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share"),
        this.$t("upgrade.info.upload_multiple_files"),
        this.$t("upgrade.info.upload_websites"),
        this.$t("upgrade.info.Collect"),
        this.$t("upgrade.info.api_access"),
        this.$t("upgrade.info.gpt"),
        this.$t("upgrade.pricing.api"),
        this.$t("upgrade.info.remove")
      ],
      UNLIMITED_MONTH_COMBO: [
        "60000" + this.$t("upgrade.info.message"),
        "25" + this.$t("upgrade.info.chatbot"),
        "15,000,000 " + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share"),
        this.$t("upgrade.info.upload_multiple_files"),
        this.$t("upgrade.info.upload_websites"),
        this.$t("upgrade.info.Collect"),
        this.$t("upgrade.info.api_access"),
        this.$t("upgrade.info.gpt"),
        this.$t("upgrade.pricing.api"),
        this.$t("upgrade.info.remove")
      ],
      UNLIMITED_YEAR_COMBO: [
        "60000" + this.$t("upgrade.info.message"),
        "25" + this.$t("upgrade.info.chatbot"),
        "15,000,000 " + this.$t("upgrade.info.characters"),
        this.$t("upgrade.info.embed"),
        this.$t("upgrade.info.share"),
        this.$t("upgrade.info.upload_multiple_files"),
        this.$t("upgrade.info.upload_websites"),
        this.$t("upgrade.info.Collect"),
        this.$t("upgrade.info.api_access"),
        this.$t("upgrade.info.gpt"),
        this.$t("upgrade.pricing.api"),
        this.$t("upgrade.info.remove")
      ]
    };
    this.nowrights = [];
    this.packageobj = new Packageobj();
    this.nowlong = localStorage.getItem("lang");
    this.combos = [
      {
        id: "",
        createdTime: 0,
        updatedTime: 0,
        type: "",
        productCode: "FREE_TRIAL_COMBO",
        startTime: 0,
        endTime: 0
      }
    ];
  }
  nowlongChanged(val, oldVal) {
    return __async(this, null, function* () {
      this.combos = yield accountBindAPI.getCombos();
      let rights = {
        FREE_TRIAL_COMBO: [
          "30" + this.$t("upgrade.info.message"),
          "1" + this.$t("upgrade.info.chatbot"),
          "400,000 " + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share")
        ],
        ENTRY_MONTH_COMBO: [
          "2000" + this.$t("upgrade.info.message"),
          "2 " + this.$t("upgrade.info.chatbot"),
          "2,000,000" + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share"),
          this.$t("upgrade.info.upload_multiple_files"),
          this.$t("upgrade.info.upload_websites"),
          this.$t("upgrade.info.Collect")
        ],
        ENTRY_YEAR_COMBO: [
          "2000" + this.$t("upgrade.info.message"),
          "2 " + this.$t("upgrade.info.chatbot"),
          "2,000,000" + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share"),
          this.$t("upgrade.info.upload_multiple_files"),
          this.$t("upgrade.info.upload_websites"),
          this.$t("upgrade.info.Collect")
        ],
        GROWTH_MONTH_COMBO: [
          "10000" + this.$t("upgrade.info.message"),
          "5 " + this.$t("upgrade.info.chatbot"),
          "6,000,000 " + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share"),
          this.$t("upgrade.info.upload_multiple_files"),
          this.$t("upgrade.info.upload_websites"),
          this.$t("upgrade.info.Collect"),
          this.$t("upgrade.info.api_access"),
          this.$t("upgrade.info.gpt")
        ],
        GROWTH_YEAR_COMBO: [
          "10000" + this.$t("upgrade.info.message"),
          "5 " + this.$t("upgrade.info.chatbot"),
          "6,000,000 " + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share"),
          this.$t("upgrade.info.upload_multiple_files"),
          this.$t("upgrade.info.upload_websites"),
          this.$t("upgrade.info.Collect"),
          this.$t("upgrade.info.api_access"),
          this.$t("upgrade.info.gpt")
        ],
        STANDARD_MONTH_COMBO: [
          "30000" + this.$t("upgrade.info.message"),
          "11 " + this.$t("upgrade.info.chatbot"),
          "8,000,000 " + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share"),
          this.$t("upgrade.info.upload_multiple_files"),
          this.$t("upgrade.info.upload_websites"),
          this.$t("upgrade.info.Collect"),
          this.$t("upgrade.info.api_access"),
          this.$t("upgrade.info.gpt"),
          this.$t("upgrade.pricing.api"),
          this.$t("upgrade.info.remove")
        ],
        STANDARD_YEAR_COMBO: [
          "30000" + this.$t("upgrade.info.message"),
          "11 " + this.$t("upgrade.info.chatbot"),
          "8,000,000 " + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share"),
          this.$t("upgrade.info.upload_multiple_files"),
          this.$t("upgrade.info.upload_websites"),
          this.$t("upgrade.info.Collect"),
          this.$t("upgrade.info.api_access"),
          this.$t("upgrade.info.gpt"),
          this.$t("upgrade.pricing.api"),
          this.$t("upgrade.info.remove")
        ],
        UNLIMITED_MONTH_COMBO: [
          "60000" + this.$t("upgrade.info.message"),
          "25" + this.$t("upgrade.info.chatbot"),
          "15,000,000 " + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share"),
          this.$t("upgrade.info.upload_multiple_files"),
          this.$t("upgrade.info.upload_websites"),
          this.$t("upgrade.info.Collect"),
          this.$t("upgrade.info.api_access"),
          this.$t("upgrade.info.gpt"),
          this.$t("upgrade.pricing.api"),
          this.$t("upgrade.info.remove")
        ],
        UNLIMITED_YEAR_COMBO: [
          "60000" + this.$t("upgrade.info.message"),
          "25" + this.$t("upgrade.info.chatbot"),
          "15,000,000 " + this.$t("upgrade.info.characters"),
          this.$t("upgrade.info.embed"),
          this.$t("upgrade.info.share"),
          this.$t("upgrade.info.upload_multiple_files"),
          this.$t("upgrade.info.upload_websites"),
          this.$t("upgrade.info.Collect"),
          this.$t("upgrade.info.api_access"),
          this.$t("upgrade.info.gpt"),
          this.$t("upgrade.pricing.api"),
          this.$t("upgrade.info.remove")
        ]
      };
      this.nowrights = rights[this.combos[0].productCode];
    });
  }
  nowlongChangedtype(val, oldVal) {
    return __async(this, null, function* () {
      this.packageobj = {
        FREE_TRIAL_COMBO: i18n.t("upgrade.info.free"),
        ENTRY_MONTH_COMBO: i18n.t("upgrade.info.entry"),
        ENTRY_YEAR_COMBO: i18n.t("upgrade.info.entry"),
        GROWTH_MONTH_COMBO: i18n.t("upgrade.info.premium"),
        GROWTH_YEAR_COMBO: i18n.t("upgrade.info.premium"),
        STANDARD_MONTH_COMBO: i18n.t("upgrade.info.professional"),
        STANDARD_YEAR_COMBO: i18n.t("upgrade.info.professional"),
        UNLIMITED_MONTH_COMBO: i18n.t("upgrade.info.business"),
        UNLIMITED_YEAR_COMBO: i18n.t("upgrade.info.business")
      };
    });
  }
  mounted() {
    return __async(this, null, function* () {
      this.bindpayments = yield accountBindAPI.getBindpayments();
      this.userPaypal = this.bindpayments.paypalAccount == null ? this.$t("account.info.none") : this.bindpayments.paypalAccount;
      this.combos = yield accountBindAPI.getCombos();
      this.nowrights = this.rights[this.combos[0].productCode];
      let userinfo = yield accountInfoAPI.getAccountInfo2();
      if (userinfo.apikey) {
        this.APIKEY = userinfo.apikey;
      }
    });
  }
  editPaypal() {
    this.isEditPaypal = true;
  }
  cancel() {
    this.isEditPaypal = false;
  }
  maskApiKey(apiKey) {
    if (apiKey && apiKey.length >= 12) {
      const visiblePart = apiKey.substring(0, 4);
      const hiddenPart = "*".repeat(apiKey.length - 12);
      const lastPart = apiKey.substring(apiKey.length - 8);
      return visiblePart + hiddenPart + lastPart;
    }
    return apiKey;
  }
  copykey() {
    navigator.clipboard.writeText(`${this.APIKEY}`).then(() => {
      this.$message.success(this.$t("route.success"));
    });
  }
  putApikey() {
    return __async(this, null, function* () {
      yield API.put(`api://base.auth/v1/refresh-apikey`).then(() => __async(this, null, function* () {
        this.APIKEY = (yield accountInfoAPI.getAccountInfo2()).apikey;
      })).catch((err) => {
        throwErrorApi.throwError(err);
      });
    });
  }
  bindPaypal() {
    return __async(this, null, function* () {
      if (this.paypalemail.length == 0) {
        this.isEditPaypal = false;
        return;
      }
      yield API.post(`api://base.auth/v1/bind-payments`, {
        bindType: "PAYPAL",
        account: this.paypalemail
      }).then(() => __async(this, null, function* () {
        this.bindpayments = yield accountBindAPI.getBindpayments();
        this.userPaypal = this.bindpayments.paypalAccount == null ? "None" : this.bindpayments.paypalAccount;
        this.isEditPaypal = false;
      })).catch((err) => {
        throwErrorApi.throwError(err);
      });
    });
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
  goUpdateserve() {
    this.$router.push("/updateservice");
  }
  // 遮蔽API密钥的函数
};
__decorateClass([
  Watch("$root.$i18n.locale", { deep: true })
], Account.prototype, "nowlongChanged", 1);
__decorateClass([
  Watch("$root.$i18n.locale", { deep: true })
], Account.prototype, "nowlongChangedtype", 1);
Account = __decorateClass([
  Component({
    components: {}
  })
], Account);
export {
  Account as default
};
