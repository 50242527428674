"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Options as APIOptions } from "@ym-framework/api-utils";
import PluginMock from "@ym-framework/api-utils-mock";
import PluginModelResponse from "@ym-framework/api-utils-model-response";
import { mocks } from "@/mock";
import router from "@/router";
let baseUrl = "https://gateway.chatimport.com/chatimport";
if (process.env.YM_LOCAL_TEST === "true") {
  baseUrl = "http://kong.shetuanbang.net/chatimport-dev";
}
if (process.env.YM_LOCAL_MOCK) {
  const _mocks = mocks;
  APIOptions.plugins.install(
    new PluginMock((mock) => {
      for (const _mock of _mocks) {
        _mock(mock);
      }
    })
  );
}
class PlugErrorResponse {
  requestHandler(request) {
    return request;
  }
  responseRejected(error) {
    var _a, _b, _c, _d, _e;
    if (((_a = error.response) == null ? void 0 : _a.status) === 403 && ((_e = (_d = (_c = (_b = error.response) == null ? void 0 : _b.data) == null ? void 0 : _c.errors) == null ? void 0 : _d.datas[0]) == null ? void 0 : _e.code) === "auth.error.code.token.expired") {
      localStorage.removeItem("token");
      router.replace("/auth");
    }
    return error;
  }
}
APIOptions.plugins.install(new PluginModelResponse());
APIOptions.plugins.install(new PlugErrorResponse());
APIOptions.host.set("account-center", () => __async(this, null, function* () {
  return {
    baseURL: baseUrl,
    headers: {
      "accept-language": localStorage.getItem("lang")
    }
  };
}));
APIOptions.host.set("base", () => {
  return {
    baseURL: baseUrl,
    headers: {
      "accept-language": localStorage.getItem("lang")
    }
  };
});
APIOptions.host.set("base.auth", () => {
  return {
    baseURL: baseUrl,
    headers: {
      Authorization: localStorage.getItem("token") || "",
      "accept-language": localStorage.getItem("lang")
    }
  };
});
