"use strict";
import Vue from "vue";
import VueRouter from "vue-router";
import { AuthAccess, GuestAccess } from "@/shared/services/access-rules/access-rules.service";
import Auth from "@/views/auth/views/Auth.vue";
import RegisterSuccess from "@/views/auth/views/RegisterSuccess.vue";
import Home from "@/views/home/views/Home.vue";
import Train from "@/views/train/views/Train.vue";
import Settings from "@/views/setting/views/Settings.vue";
import Intergrations from "@/views/intergrations/views/Intergrations.vue";
import Analytics from "@/views/analytics/Analytics.vue";
import Dashboard from "@/views/dashboard/views/Dashboard.vue";
import RobotImplant from "@/views/implant/RobotImplant.vue";
import Robotindependent from "@/views/implant/Robotindependent.vue";
import RobotShare from "@/views/share/views/RobotShare.vue";
import UpdateService from "@/views/updateService/views/UpdateService.vue";
import ChatBot from "@/views/chatbot/ChatBot.vue";
import Oauth from "@/views/authorization/Oauth.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/auth",
    name: "auth",
    meta: {
      title: "route.auth"
    },
    component: Auth
  },
  {
    path: "/registerSuccess",
    name: "registerSuccess",
    meta: {
      title: "route.register"
    },
    component: RegisterSuccess
  },
  {
    path: "/oauth-google",
    name: "oauth",
    meta: {
      title: "oauth"
    },
    component: Oauth
  },
  {
    path: "/robotimplant",
    name: "robotimplant",
    meta: {
      title: "robotimplant"
    },
    component: RobotImplant
  },
  {
    path: "/independent",
    name: "independent",
    component: Robotindependent
  },
  {
    path: "/updateservice",
    name: "updateservice",
    meta: {
      title: "route.updateservice"
    },
    component: UpdateService
  },
  {
    path: "/chatbot",
    name: "chatbot",
    meta: {
      access: new AuthAccess(),
      title: "route.chatbot",
      menuactive: "2"
    },
    component: ChatBot
  },
  {
    path: "/train",
    name: "train",
    meta: {
      access: new AuthAccess(),
      title: "route.train",
      menuactive: "3"
    },
    component: Train
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      access: new AuthAccess(),
      title: "route.settings",
      menuactive: "4"
    },
    component: Settings
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      access: new AuthAccess(),
      title: "route.dashboard",
      menuactive: "5"
    },
    component: Dashboard
  },
  {
    path: "/analytics",
    name: "analytics",
    meta: {
      access: new AuthAccess(),
      title: "route.analytics",
      menuactive: "6"
    },
    component: Analytics
  },
  {
    path: "/intergrations",
    name: "intergrations",
    meta: {
      access: new AuthAccess(),
      title: "route.intergrations",
      menuactive: "7"
    },
    component: Intergrations
  },
  {
    path: "/share",
    name: "share",
    meta: {
      access: new AuthAccess(),
      title: "route.share",
      menuactive: "8"
    },
    component: RobotShare
  },
  {
    path: "/",
    name: "Home",
    meta: {
      access: new AuthAccess(),
      title: "route.home",
      menuactive: "1"
    },
    component: Home
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  var _a;
  let _access = new GuestAccess();
  if ((_a = to.meta) == null ? void 0 : _a.access) {
    _access = to.meta.access;
  }
  _access.next(to, from, next);
});
export default router;
