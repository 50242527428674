import { render, staticRenderFns } from "./RobotFiles.vue?vue&type=template&id=47b10080&scoped=true&"
import script from "./RobotFiles.vue?vue&type=script&lang=ts&"
export * from "./RobotFiles.vue?vue&type=script&lang=ts&"
import style0 from "./RobotFiles.vue?vue&type=style&index=0&id=47b10080&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b10080",
  null
  
)

export default component.exports