"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
import store from "@/store/index";
class RobotTrainApi {
  // 获取机器人qa字符数
  getusedCharacters(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/train/qa/used-characters`
      );
      return response.data.usedCharacters;
    });
  }
  // 获取机器人训练进度
  getTrainingQa(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/train-task`
      );
      return response.data;
    });
  }
  // 获取机器人训练历史
  getTrainingQaHistory(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/train-task/records`
      );
      return response.data;
    });
  }
  // 获取机器人训练历史聊天记录
  getChattingRecords(id, visitor_id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base/v1/chatbots/${id}/visitor/${visitor_id}/chat-history`
      );
      return response.data;
    });
  }
  //获取机器人语料文件列表
  getFileList(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/train/file`
      );
      return response.data;
    });
  }
  //删除聊天机器人语料文件
  delFile(id, fileid) {
    return __async(this, null, function* () {
      const response = yield API.delete(
        `api://base.auth/v1/chatbots/${id}/train/file/${fileid}`
      );
      return response.data;
    });
  }
  //获取机器人语料使用统计
  getUsed(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/train/used-statistics`
      );
      store.commit("chatbot/update", response.data);
      return response.data;
    });
  }
  //获取机器人网站列表
  getWebsiteList(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/train/website`
      );
      return response.data;
    });
  }
  //删除机器人网站列表
  delWebsite(chabot_id, webid) {
    return __async(this, null, function* () {
      const response = yield API.delete(
        `api://base.auth/v1/chatbots/${chabot_id}/train/website/${webid}`
      );
      return response.data;
    });
  }
  //获取text
  getText(id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/${id}/train/text`
      );
      return response.data;
    });
  }
  // 最大上传20mb
  upload() {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chatbots/max-file-limit`
      );
      return response.data;
    });
  }
}
export const robottrainAPI = new RobotTrainApi();
